import React, { useReducer } from 'react';

import concursoContext from './concursoContext';
import concursoReducer from './concursoReducer';
import {
            OBTENER_CONCURSOS,
            OBTENER_CONCURSOS_POR_ID,
            LISTA_PARTICIPANTES,
            CONCURSO_ACTUAL
        } from '../../types';

import clienteAxios from "../../config/axios";

const ConcursoState = props => {

    const initialState = {    
        concursos: [],
        concursosPorId: [],
        listaParticipantes: false,
        concurso: null
    }

    // Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(concursoReducer, initialState);

    const mostrarLista = () => {
        dispatch({
            type: LISTA_PARTICIPANTES
        })
    }

    // Obtener los concursos
    const obtenerConcursos = async () => {
        try {
            const resultado = await clienteAxios.get('/api/concursos');
            dispatch({
                type:OBTENER_CONCURSOS,
                payload: resultado.data.concursos
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                //type: PROYECTO_ERROR,
                payload: alerta
            })
        }
    }

    // Obtener los participantes
    const obtenerConcursosPorId = async id => {
        try {
            const resultado = await clienteAxios.get('/api/concursoPorId/' + id);
            dispatch({
                type:OBTENER_CONCURSOS_POR_ID,
                payload: resultado.data.concursosPorId
            })
        } catch (error) {
            console.log(error);
        }
    }

    // Selecciona el Concurso que el usuario dio click
    const concursoActual = concursoId => {
        dispatch({
            type: CONCURSO_ACTUAL,
            payload: concursoId
        })
    } 

    return(
        <concursoContext.Provider
            value={{
                concursos: state.concursos,
                concursosPorId: state.concursosPorId,
                listaParticipantes: state.listaParticipantes,
                concurso: state.concurso,
                obtenerConcursos,
                obtenerConcursosPorId,
                mostrarLista,
                concursoActual
            }}
        >
            {props.children}
        </concursoContext.Provider>
    )
}

export default ConcursoState;