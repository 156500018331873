import React, {useState, useEffect, useContext} from 'react';
import AlertaContext from '../../context/alertas/alertaContext';
import AuthContext from '../../context/autenticacion/authContext';
import { Link } from 'react-router-dom';

// Import styles
import '../../design/styles/login.scss';

// Import partial
import FooterHome from '../partials/FooterRegister';

const Login = (props) => {

    //extraer los valores del context
    const alertaContext = useContext(AlertaContext);
    const {alerta, mostrarAlerta} = alertaContext;

    const authContext = useContext(AuthContext);
    const {mensaje, autenticado, iniciarSesion} = authContext;

    // En caso de que el password o usuario no exista
    useEffect(() => {
        if(autenticado) {
            props.history.push('/dashboard');
        }

        if (mensaje) {
            mostrarAlerta(mensaje.msg, mensaje.categoria);
        }
        // eslint-disable-next-line
    }, [mensaje, autenticado, props.history]);

    //State para iniciar sesión
    const [usuario, guardarUsuario] = useState({
        email: '',
        password: ''
    });

    // Extrar de usuario
    const {email, password} = usuario;

    const onChange = e => {
        guardarUsuario({
            ...usuario,
            [e.target.name] : [e.target.value]
        });
    };

    // Cuando el usuario quiere iniciar sesión
    const onSubmit = e => {
        e.preventDefault();

        // validar que no haya campos vacíos
        if(email.toString().trim() === '' || password.toString().trim() === '') {
            mostrarAlerta('Todos los campos son obligatorios', 'alerta-error');
            return;
        }

        // Pasarlo al action
        iniciarSesion({
            email: email.toString(),
            password: password.toString()
        });
    }

    return (
        <div className='outer-wrapper'>
            {alerta ? (<div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>) : null}
            <section className='block login'>
                <div className='background'></div>
                <div className='holder'>
                    <div className='container-fluid'>
                        <div className='header'>
                            <div className='title'>Login</div>
                        </div>
                        <div className="content">
                            <form
                                onSubmit={onSubmit}
                            >
                                <div className="input-container">
                                    <input
                                        type='email'
                                        id='email'
                                        name='email'
                                        placeholder="@gmail.com"
                                        value={email}
                                        onChange={onChange}
                                    />
                                    <label htmlFor="email">e-mail*</label>
                                </div>
                                <div className="input-container">
                                    <div className="input-group">
                                        <input
                                            type='password'
                                            id='password'
                                            name='password'
                                            value={password}
                                            onChange={onChange}
                                        />
                                    </div>
                                    <label htmlFor="password">Contraseña*</label>
                                </div>
                                <button className="submit">Entrar</button>
                                <Link to={'/recuperar-contrasena'} className="forgot-password">Olvidé mi contraseña</Link>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <FooterHome />
        </div>
    );
}

export default Login;