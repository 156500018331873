import React, { useState, useEffect } from 'react';
import MainNavigation from '../partials/MainNavigation';
import FooterRegister from '../partials/FooterRegister';
import Competition from '../competitions/Competition';

const Dashboard = () => {

    return (
        <div className='outer-wrapper'>
            <MainNavigation/>
            <Competition/>
            <FooterRegister/>
        </div>
    );
}

export default Dashboard;