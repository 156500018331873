import React, {useState, useEffect, useContext} from 'react';
import AlertaContext from '../../context/alertas/alertaContext';
import AuthContext from '../../context/autenticacion/authContext';
import { Link } from 'react-router-dom';

// Import styles
import '../../design/styles/login.scss';

// Import partial
import FooterHome from '../partials/FooterRegister';

const Password = (props) => {

    const [mensajeCategoria, setMensaje] = useState('')

    //extraer los valores del context
    const alertaContext = useContext(AlertaContext);
    const {alerta, mostrarAlerta} = alertaContext;

    const authContext = useContext(AuthContext);
    const {mensaje, autenticado, actualizarUsuarioPorEmail} = authContext;

    // En caso de que el password o usuario no exista
    useEffect(() => {
        if(autenticado) {
            props.history.push('/dashboard');
        }

        if (mensaje) {
            setMensaje(mensaje.categoria)
            mostrarAlerta(mensaje.msg, mensaje.categoria);
        }
        // eslint-disable-next-line
    }, [mensaje, autenticado, props.history]);

    //State para iniciar sesión
    const [usuario, guardarUsuario] = useState({
        email: '',
        password: '',
        confirmar: ''
    });

    // Extrar de usuario
    const {email, password, confirmar} = usuario;

    const onChange = e => {
        guardarUsuario({
            ...usuario,
            [e.target.name] : [e.target.value]
        });
    };

    // Cuando el usuario quiere iniciar sesión
    const onSubmit = e => {
        e.preventDefault();

        // validar que no haya campos vacíos
        if(email.toString().trim() === '' || password.toString().trim() === '' || confirmar.toString().trim() === '') {
            mostrarAlerta('Todos los campos son obligatorios', 'alerta-error');
            return;
        }

        // Password mínimo de 6 caracteres
        
        if(password.toString().length < 6) {
            mostrarAlerta('El password debe ser de al menos 6 caracteres', 'alerta-error');
            return;
        }

        // Los dos passwords son iguales
        if (password.toString() !== confirmar.toString()) {
            mostrarAlerta('Los passwords no son iguales', 'alerta-error');
            return;
        }

        // Pasarlo al action
        actualizarUsuarioPorEmail({
            email: email.toString(),
            password: password.toString()
        });
    }

    return (
        <div className='outer-wrapper'>
            {alerta ? (<div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>) : null}
            <section className='block login'>
                <div className='background'></div>
                <div className='holder'>
                    <div className='container-fluid'>
                        <div className='header'>
                            <div className='title'>Recuperar contraseña</div>
                        </div>
                        <div className="content">
                            {
                                (mensajeCategoria == 'alerta-ok')
                                    ?
                                        <Link to={'/login'} className="password-succeed">{'< Regresar'}</Link>
                                    :
                                        <form
                                            onSubmit={onSubmit}
                                        >
                                            <div className="input-container">
                                                <input
                                                    type='email'
                                                    id='email'
                                                    name='email'
                                                    placeholder="@gmail.com"
                                                    value={email}
                                                    onChange={onChange}
                                                />
                                                <label htmlFor="email">e-mail*</label>
                                            </div>
                                            <div className="input-container input-container-small">
                                                <div className="input-group">
                                                    <input
                                                        type='password'
                                                        id='password'
                                                        name='password'
                                                        placeholder='Tu Password'
                                                        value={password}
                                                        onChange={onChange}
                                                    />
                                                </div>
                                                <label htmlFor="password">Escribe una nueva contraseña*</label>
                                            </div>
                                            <div className="input-container input-container-large">
                                                <div className="input-group">
                                                    <input
                                                        type='password'
                                                        id='confirmar'
                                                        name='confirmar'
                                                        placeholder='Repite Tu Password'
                                                        value={confirmar}
                                                        onChange={onChange}
                                                    />
                                                </div>
                                                <label htmlFor="confirm-password">Confirmar contraseña*</label>
                                            </div>
                                            <button className="submit">Entrar</button>
                                            <Link to={'/'} className="forgot-password">Cancelar</Link>
                                        </form>
                            }
                        </div>
                    </div>
                </div>
            </section>

            <FooterHome />
        </div>
    );
}

export default Password;