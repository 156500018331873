import React, {useState, useEffect, useContext} from 'react';
import listaPilotosContext from '../../context/listaPilotos/listaPilotosContext';
import AuthContext from '../../context/autenticacion/authContext';

// Import styles
import '../../design/styles/pilots-list.scss';
import '../../design/styles/questions.scss';

import flag01 from '../../design/imgs/reino-unido.svg';
import flag02 from '../../design/imgs/alemania.svg';
import flag03 from '../../design/imgs/australia.svg';
import flag04 from '../../design/imgs/canada.svg';
import flag05 from '../../design/imgs/china.svg';
import flag06 from '../../design/imgs/espana.svg';
import flag07 from '../../design/imgs/finlandia.svg';
import flag08 from '../../design/imgs/francia.svg';
import flag09 from '../../design/imgs/japon.svg';
import flag10 from '../../design/imgs/mexico.svg';
import flag11 from '../../design/imgs/polonia.svg';
import flag12 from '../../design/imgs/tailandia.svg';
import flag13 from '../../design/imgs/dinamarca.jpg';
import flag14 from '../../design/imgs/holanda.jpg';
import flag15 from '../../design/imgs/usa.png';
import flag16 from '../../design/imgs/qatar.jpg';

import pilot01 from '../../design/imgs/Max.png';
import pilot02 from '../../design/imgs/Sergio.png';
import pilot03 from '../../design/imgs/Lewis.png';
import pilot04 from '../../design/imgs/George.png';
import pilot05 from '../../design/imgs/Carlos.png';
import pilot06 from '../../design/imgs/Charles.png';
import pilot09 from '../../design/imgs/Esteban.png';
import pilot11 from '../../design/imgs/Pierre.png';
import pilot07 from '../../design/imgs/Lando.png';
import pilot08 from '../../design/imgs/Oscar.png';
import pilot17 from '../../design/imgs/Valteri.png';
import pilot18 from '../../design/imgs/Guanyu.png';
import pilot13 from '../../design/imgs/Lance.png';
import pilot10 from '../../design/imgs/Fernando.png';
import pilot20 from '../../design/imgs/Kevin.png';
import pilot14 from '../../design/imgs/Nico.png';
import pilot12 from '../../design/imgs/Yuki.png';
import pilot15 from '../../design/imgs/Alexander.png';
import pilot16 from '../../design/imgs/Logan.png';
import pilot19 from '../../design/imgs/Daniel.png';

const ListaPilotos = ({pilotoSeleccionado, SeleccionarPiloto, participantesInscritos, selectedOptionPilot, setSelectedOptionPilot}) => {

    let comodinPiloto = 0;

    // Extraer concursos de state inicial
    const pilotosContext = useContext(listaPilotosContext);
    const {pilotos, obtenerPilotos} = pilotosContext;

    const onPilotSelection = value => () => {
        SeleccionarPiloto(value);
        setSelectedOptionPilot('');
        localStorage.setItem('comodinPiloto', (comodinPiloto + 1));
    };

    // Extraer la información de autenticación
    const authContext = useContext(AuthContext);
    const {usuario} = authContext;

    const [isOpenPilot, setIsOpenPilot] = useState(false);

    const togglingPilot = () => setIsOpenPilot(!isOpenPilot);

    const onOptionClickedPilot = value => () => {
        setSelectedOptionPilot(value);
        setIsOpenPilot(false);
        SeleccionarPiloto(value);
        localStorage.setItem('comodinPiloto', (comodinPiloto));
    };

    const currentParticipante = localStorage.getItem('currentParticipant');

    useEffect(() => {
        obtenerPilotos();
    }, [])

    return (
        <div className='pilots-list'>
            <img className='hidden' src={pilot01} alt='Piloto' key={pilot01}/>
            <img className='hidden' src={pilot02} alt='Piloto' key={pilot02}/>
            <img className='hidden' src={pilot03} alt='Piloto' key={pilot03}/>
            <img className='hidden' src={pilot04} alt='Piloto' key={pilot04}/>
            <img className='hidden' src={pilot05} alt='Piloto' key={pilot05}/>
            <img className='hidden' src={pilot06} alt='Piloto' key={pilot06}/>
            <img className='hidden' src={pilot07} alt='Piloto' key={pilot07}/>
            <img className='hidden' src={pilot08} alt='Piloto' key={pilot08}/>
            <img className='hidden' src={pilot09} alt='Piloto' key={pilot09}/>
            <img className='hidden' src={pilot10} alt='Piloto' key={pilot10}/>
            <img className='hidden' src={pilot11} alt='Piloto' key={pilot11}/>
            <img className='hidden' src={pilot12} alt='Piloto' key={pilot12}/>
            <img className='hidden' src={pilot13} alt='Piloto' key={pilot13}/>
            <img className='hidden' src={pilot14} alt='Piloto' key={pilot14}/>
            <img className='hidden' src={pilot15} alt='Piloto' key={pilot15}/>
            <img className='hidden' src={pilot16} alt='Piloto' key={pilot16}/>
            <img className='hidden' src={pilot17} alt='Piloto' key={pilot17}/>
            <img className='hidden' src={pilot18} alt='Piloto' key={pilot18}/>
            <img className='hidden' src={pilot19} alt='Piloto' key={pilot19}/>
            <img className='hidden' src={pilot20} alt='Piloto' key={pilot20}/>
            <img className='hidden' src={flag01} alt='Bandera' key={flag01}/>
            <img className='hidden' src={flag02} alt='Bandera' key={flag02}/>
            <img className='hidden' src={flag03} alt='Bandera' key={flag03}/>
            <img className='hidden' src={flag04} alt='Bandera' key={flag04}/>
            <img className='hidden' src={flag05} alt='Bandera' key={flag05}/>
            <img className='hidden' src={flag06} alt='Bandera' key={flag06}/>
            <img className='hidden' src={flag07} alt='Bandera' key={flag07}/>
            <img className='hidden' src={flag08} alt='Bandera' key={flag08}/>
            <img className='hidden' src={flag09} alt='Bandera' key={flag09}/>
            <img className='hidden' src={flag10} alt='Bandera' key={flag10}/>
            <img className='hidden' src={flag11} alt='Bandera' key={flag11}/>
            <img className='hidden' src={flag12} alt='Bandera' key={flag12}/>
            <img className='hidden' src={flag13} alt='Bandera' key={flag13}/>
            <img className='hidden' src={flag14} alt='Bandera' key={flag14}/>
            <img className='hidden' src={flag15} alt='Bandera' key={flag15}/>
            <img className='hidden' src={flag16} alt='Bandera' key={flag16}/>
            
            {pilotos.map((piloto, index) => (
                <div    
                    className='pilot-container'
                    key={index}
                >
                    {participantesInscritos.map(participante => (
                        (currentParticipante === participante.id_participante)
                            ?
                                (
                                    (piloto.nombre + ' ' + piloto.apellido) === participante.pick_piloto_01 ||
                                    (piloto.nombre + ' ' + piloto.apellido) === participante.pick_piloto_02 ||
                                    (piloto.nombre + ' ' + piloto.apellido) === participante.pick_piloto_03 ||
                                    (piloto.nombre + ' ' + piloto.apellido) === participante.pick_piloto_04 ||
                                    (piloto.nombre + ' ' + piloto.apellido) === participante.pick_piloto_05 ||
                                    (piloto.nombre + ' ' + piloto.apellido) === participante.pick_piloto_06 ||
                                    (piloto.nombre + ' ' + piloto.apellido) === participante.pick_piloto_07 ||
                                    (piloto.nombre + ' ' + piloto.apellido) === participante.pick_piloto_08 ||
                                    (piloto.nombre + ' ' + piloto.apellido) === participante.pick_piloto_09 ||
                                    (piloto.nombre + ' ' + piloto.apellido) === participante.pick_piloto_10 ||
                                    (piloto.nombre + ' ' + piloto.apellido) === participante.pick_piloto_11 ||
                                    (piloto.nombre + ' ' + piloto.apellido) === participante.pick_piloto_12 ||
                                    (piloto.nombre + ' ' + piloto.apellido) === participante.pick_piloto_13 ||
                                    (piloto.nombre + ' ' + piloto.apellido) === participante.pick_piloto_14 ||
                                    (piloto.nombre + ' ' + piloto.apellido) === participante.pick_piloto_15 ||
                                    (piloto.nombre + ' ' + piloto.apellido) === participante.pick_piloto_16 ||
                                    (piloto.nombre + ' ' + piloto.apellido) === participante.pick_piloto_17 ||
                                    (piloto.nombre + ' ' + piloto.apellido) === participante.pick_piloto_18 ||
                                    (piloto.nombre + ' ' + piloto.apellido) === participante.pick_piloto_19 ||
                                    (piloto.nombre + ' ' + piloto.apellido) === participante.pick_piloto_20 ||
                                    (piloto.nombre + ' ' + piloto.apellido) === participante.pick_piloto_21 ||
                                    (piloto.nombre + ' ' + piloto.apellido) === participante.pick_piloto_22
                                )   
                                        ?
                                            <div className='already-selected'></div>
                                        :
                                            null
                            :
                                null
                    ))}
                    <div
                        onClick={onPilotSelection(piloto.nombre + ' ' + piloto.apellido)}
                        className={(pilotoSeleccionado === (piloto.nombre + ' ' + piloto.apellido) && selectedOptionPilot === '') ? 'pilot-wrapper selected' : 'pilot-wrapper'}
                    >
                        <div className='info' id={piloto.equipo}>
                            <div className='name'>{piloto.nombre}</div>
                            <div className='lastname'>{piloto.apellido}</div>
                        </div>
                        {
                            piloto.bandera_url !== ''
                            ?
                            <div className='flag'><img src={piloto.bandera_url} alt='Bandera'/></div>
                            :
                            null
                        }
                        <div className='pilot-image'><img src={piloto.foto_url} alt='Piloto'/></div>
                    </div>
                </div>
            ))}
            <div className='questions comodins' key={Math.random()}>
                <div className='comodin'>
                    {participantesInscritos.map(participante => (
                        (participante.id_participante === usuario._id) ?
                            (participante.comodines_disponibles_pilotos > 0) ?
                                <div>
                                    <div className='hidden'>{comodinPiloto = participante.comodines_disponibles_pilotos}</div>
                                    {
                                        (participante.comodines_disponibles_pilotos === 1)
                                            ?
                                                <div className='title'>Selecciona tu comodín para pilotos.</div>
                                            :
                                                <div className='title'>Tienes {participante.comodines_disponibles_pilotos} comodines disponibles</div>
                                    }
                                    <div className='custom-select'>
                                        <div className='indication' onClick={togglingPilot}>
                                            <span className='text'>{selectedOptionPilot || "Elige tu respuesta"}</span>
                                        </div>
                                        {isOpenPilot && (
                                            <div className='options-wrapper'>
                                                <ul className='list-opts'>
                                                    {pilotos.map(piloto => (
                                                        <li
                                                            onClick={
                                                                onOptionClickedPilot(piloto.nombre + ' ' + piloto.apellido + '*')
                                                            }
                                                            key={Math.random()}
                                                        >
                                                            {piloto.nombre + ' ' + piloto.apellido}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            :
                            <div className='title'>Ya no tienes comodines disponibles</div>
                        : null
                    ))}
                    </div>
                </div>
            </div>
    );
}

export default ListaPilotos;