import React, {useState, useContext, useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import AlertaContext from '../../context/alertas/alertaContext';
import AuthContext from '../../context/autenticacion/authContext';
import emailjs from '@emailjs/browser';

// Import styles
import backIcon from '../../design/imgs/arrow-l-1.svg';
import creditosImage from '../../design/imgs/creditos.jpg'
import '../../design/styles/credits.scss';
import '../../design/styles/states-contact.scss';

// Import Partial
import MainNavigation from '../partials/MainNavigation';
import FooterHome from '../partials/FooterRegister';

const Credits = () => {

    const [total, setTotal] = useState(0)

    const setCredits = (e) => {

        let dataCredits = e.target.getAttribute('data-credit'),
            credits = parseInt(dataCredits);

        if(e.target.classList.contains('active')) {
            e.target.classList.remove('active')
            if(total > 0) {
                setTotal(total - dataCredits)
            }
        } else {
            e.target.classList.add('active')
            setTotal(total + credits)
        }
    }

    //extraer los valores del context
    const alertaContext = useContext(AlertaContext);
    const {alerta, mostrarAlerta} = alertaContext;

    // Extraer la información de autenticación
    const authContext = useContext(AuthContext);
    const {usuario, usuarioAutenticado} = authContext;

    useEffect(() => {
        usuarioAutenticado();
        // eslint-disable-next-line
    }, [])

    const form = useRef();

    // state para mandar correo
    const [infoCorreo, guardarInfoCorreo] = useState({
        nombre: usuario.nombre + ' ' + usuario.apellidos,
        correo: usuario.email,
        creditos: total
    });

    // Extrar de infoCorreo
    const {nombre, correo, creditos} = infoCorreo;

    const onChange = e => {
        guardarInfoCorreo({
            ...infoCorreo,
            [e.target.name] : [e.target.value]
        });
    };

    const reset = () => {
        guardarInfoCorreo({
            nombre: usuario.nombre + ' ' + usuario.apellidos,
            correo: usuario.email,
            creditos: 0
        });
    };

    const sendEmail = (e) => {
        e.preventDefault();

        // validar que no haya campos vacíos
        if(nombre.toString().trim() === '' || correo.toString().trim() === '' || total === 0) {
            mostrarAlerta('Todos los campos son obligatorios', 'alerta-error');
            return;
        }

        let statusMessage = document.querySelector('.status-message'),
            wrapperForm = document.querySelector('.wrapper-content-form');

        statusMessage.classList.remove('hidden');
        wrapperForm.classList.add('partial-hide');
        statusMessage.innerHTML = 
        `<div class="sending">
            <div class="message">
                <div class="icon icon-sending"></div>
                <div class="text">Solicitando créditos...</div>
            </div>
        </div>`;

        emailjs.sendForm('service_t9pd4vm', 'template_yrxckpt', form.current, 'H086lp0RHCmGPEA91')
            .then((result) => {
                setTimeout(() => {
                    statusMessage.innerHTML = 
                    `<div class="success">
                        <div class="message">
                            <div class="icon icon-success"></div>
                            <div class="text">
                                <p>Hola, ${usuario.nombre}. Hemos recibido tu solicitud de ${total.toLocaleString('en')} créditos correctamente.</p>
                                <br>
                                <p><strong>Para poder activarlos, es necesario que realices el pago de los mismos</strong> en cualquiera de las siguientes maneras:</p>
                                <br>
                                <p><strong>Transferencia Bancaria:</strong></p>
                                <p>Banbajio</p>
                                <br>
                                <p>Clabe Interbancaria 030650900031397503</p>
                                <p>V & V Nova Service SA de CV </p>
                                </>*Importante: mandar el comprobante por WhatsApp al número: 221 282 8105 para activar tus créditos.</p>
                                <br>
                                <p><strong>Pago en efectivo:</strong></p>
                                <p>Mandar un mensaje por WhatsApp al número: 221 282 8105</p>
                                <p>para recibir indicaciones.</p>
                            </div>
                        </div>
                    </div>`;
                    console.log(result.text);
                }, 3000);
            }, (error) => {
                setTimeout(() => {
                    statusMessage.innerHTML = '<div class="error">Hubo un error, inténtalo más tarde.</div>';
                    console.log(error.text);
                }, 3000);
            });
        
        setTimeout(() => {

            statusMessage.classList.add('hidden');
            wrapperForm.classList.remove('partial-hide');

            e.target.reset();
            reset();
        }, 12000000);
    }

    return (

        <div className='outer-wrapper'>
            <MainNavigation/>
            {alerta ? (<div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>) : null}
            <section className='block credits'>
                <div className='background'></div>
                <div className='holder'>
                    <div className='container-fluid'>
                        <div className='content'>
                            <div className='flex-content'>
                                <div className='left-side'>
                                    <div className='header'>
                                        <div className='title'>Créditos</div>
                                        <div className='desc'><b>¿Listo para competir?</b> Solicita tus créditos llenando la siguiente información. Tus créditos son el combustible que necesitas para entrar y competir en los diferentes bloques y eventos. No te quedes corto, te recomiendo llenar bien tu tanque.</div>
                                    </div>
                                    <div className='image-wrapper hidden-xs'>
                                        <div className='image'>
                                            <img
                                                src={creditosImage}
                                                alt="Imagen Créditos"
                                                />
                                        </div>
                                    </div>
                                </div>
                                <div className='form-wrapper'>
                                    <div className='text-wrapper'>
                                        <div className='text-one'>¿LISTO PARA COMPETIR?</div>
                                        <div className='text-two'>
                                            <p>Selecciona los concursos para los que queieres solicitar créditos; recuerda que puedes jugar más de un concurso, entre más juegues, más posibilidades tienes de ganar.</p>
                                            <p>Una vez que hagas tu solicitud, recibirás por correo la información para que puedas realizar tu pago y se den de alta tus créditos.</p>
                                        </div>
                                    </div>
                                    <form
                                        ref={form}
                                        onSubmit={sendEmail}
                                    >
                                        <div className="status-message hidden"></div>
                                        <div className="wrapper-content-form">
                                            <div className='inputs-wrapper flex-input'>
                                                <div className='input-container'>
                                                    <input
                                                        className='normal-input'
                                                        id='name'
                                                        name='nombre'
                                                        type='text'
                                                        data-validate='notEmpty'
                                                        data-placeholder=''
                                                        placeholder=''
                                                        value={nombre}
                                                        onChange={onChange}
                                                    />
                                                    <label htmlFor='name'>Nombre*</label>
                                                </div>
                                                <div className='input-container mt'>
                                                    <input
                                                        className='normal-input'
                                                        id='email'
                                                        type='text'
                                                        name='correo'
                                                        data-validate='email'
                                                        data-placeholder=''
                                                        placeholder=''
                                                        value={correo}
                                                        onChange={onChange}
                                                    />
                                                    <label htmlFor='email'>Correo*</label>
                                                </div>
                                            </div>
                                            <div className='concursos-content'>
                                                <div className='heading-wrapper'>
                                                    <div className='heading-element'>Concursos 2024</div>
                                                    <div className='heading-element'>Créditos</div>
                                                    <div className='heading-element'>Modalidad</div>
                                                    <div className='heading-element'>Jugadores</div>
                                                </div>
                                                <div className='option' data-credit='1500' onClick={setCredits}>
                                                    <div className='opt-element main-opt'>Women Elite</div>
                                                    <div className='opt-element'>1,500.00</div>
                                                    <div className='opt-element'>Con sorteo</div>
                                                    <div className='opt-element'>20</div>
                                                </div>
                                                <div className='option' data-credit='3000' onClick={setCredits}>
                                                    <div className='opt-element main-opt'>Box</div>
                                                    <div className='opt-element'>3,000.00</div>
                                                    <div className='opt-element'>Con sorteo</div>
                                                    <div className='opt-element'>20</div>
                                                </div>
                                                <div className='option' data-credit='6000' onClick={setCredits}>
                                                    <div className='opt-element main-opt'>Warm Up</div>
                                                    <div className='opt-element'>6,000.00</div>
                                                    <div className='opt-element'>Con sorteo</div>
                                                    <div className='opt-element'>20</div>
                                                </div>
                                                <div className='option' data-credit='6000' onClick={setCredits}>
                                                    <div className='opt-element main-opt'>Warm Up 2</div>
                                                    <div className='opt-element'>6,000.00</div>
                                                    <div className='opt-element'>Con sorteo</div>
                                                    <div className='opt-element'>20</div>
                                                </div>
                                                <div className='option' data-credit='3500' onClick={setCredits}>
                                                    <div className='opt-element main-opt'>GOAT</div>
                                                    <div className='opt-element'>3,500.00</div>
                                                    <div className='opt-element'>Sin sorteo</div>
                                                    <div className='opt-element'>Ilimitado</div>
                                                </div>
                                            </div>
                                            <div className='inputs-wrapper'>
                                                <div className='input-container'>
                                                    <input
                                                        className='credits-input'
                                                        id='credits'
                                                        type='text'
                                                        name='creditos'
                                                        data-placeholder=''
                                                        placeholder=''
                                                        value={total.toLocaleString('en')}
                                                        onChange={onChange}
                                                    />
                                                    <label htmlFor='credits'>TOTAL</label>
                                                </div>
                                            </div>
                                            <input type='submit' value='Solicitar' />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                {usuario
                    ?
                        <Link to={'/dashboard'} className='back'>
                            <div className='icon'>
                                <img
                                    src={backIcon}
                                    alt="Regresar"
                                />
                            </div>
                            <div className='text'>Regresar</div>
                        </Link>
                    :
                        <Link to={'/'} className='back'>
                            <div className='icon'>
                                <img
                                    src={backIcon}
                                    alt="Regresar"
                                />
                            </div>
                            <div className='text'>Regresar</div>
                        </Link>
                }
            </section>
            <FooterHome/>
        </div>
    );
}

export default Credits;