import {
    OBTENER_PILOTOS
} from '../../types';

const reducer = (state, action) => {
switch(action.type) {
    case OBTENER_PILOTOS:
        return {
            ...state,
            pilotos: action.payload
        }
    default:
        return state;
    }
}

export default reducer;