import React, { useState, useEffect, useContext } from 'react';
import listaPilotosContext from '../../context/listaPilotos/listaPilotosContext';

// Import styles
import '../../design/styles/questions.scss';

import arrow from '../../design/imgs/expand-arrow.svg';

const options = [
    '1º',
    '2º',
    '3º',
    '4º',
    '5º',
    '6º',
    '7º',
    '8º',
    '9º',
    '10º',
    '11º',
    '12º',
    '13º',
    '14º',
    '15º',
    '16º',
    '17º',
    '18º',
    '19º',
    '20º',
    'DNF',
    'DNS'
];

const Preguntas = ({
        selectedOption,
        setSelectedOption,
        selectedOptionPilotDnf,
        setSelectedOptionPilotDnf,
        selectedOptionPilotDhl,
        setSelectedOptionPilotDhl,
        selectedPosOne,
        setSelectedPosOne,
        selectedPosTwo,
        setSelectedPosTwo,
        selectedPosThree,
        setSelectedPosThree,
        selectedPosFour,
        setSelectedPosFour,
        selectedPosFive,
        setSelectedPosFive
    }) => {

    const [ posOneOpen, setPosOneOpen ] = useState(false)
    const [ posTwoOpen, setPosTwoOpen ] = useState(false)
    const [ posThreeOpen, setPosThreeOpen ] = useState(false)
    const [ posFourOpen, setPosFourOpen ] = useState(false)
    const [ posFiveOpen, setPosFiveOpen ] = useState(false)

    const togglingOne = () => setPosOneOpen(!posOneOpen);
    const onOptionClickedOne = value => () => {
        setPosOneOpen(false);
        setSelectedPosOne(value)
    };

    const togglingTwo = () => setPosTwoOpen(!posTwoOpen);
    const onOptionClickedTwo = value => () => {
        setPosTwoOpen(false);
        setSelectedPosTwo(value)
    };

    const togglingThree = () => setPosThreeOpen(!posThreeOpen);
    const onOptionClickedThree = value => () => {
        setPosThreeOpen(false);
        setSelectedPosThree(value)
    };

    const togglingFour = () => setPosFourOpen(!posFourOpen);
    const onOptionClickedFour = value => () => {
        setPosFourOpen(false);
        setSelectedPosFour(value)
    };

    const togglingFive = () => setPosFiveOpen(!posFiveOpen);
    const onOptionClickedFive = value => () => {
        setPosFiveOpen(false);
        setSelectedPosFive(value)
    };

    let concurso = JSON.parse(localStorage.getItem('currentConcurso'));
    
    // Extraer concursos de state inicial
    const pilotosContext = useContext(listaPilotosContext);
    const {pilotos, obtenerPilotos} = pilotosContext;

    const [isOpen, setIsOpen] = useState(false);

    const toggling = () => setIsOpen(!isOpen);

    const onOptionClicked = value => () => {
        setSelectedOption(value);
        setIsOpen(false);
    };

    const [isOpenPilotDnf, setIsOpenPilotDnf] = useState(false);

    const togglingPilotDnf = () => setIsOpenPilotDnf(!isOpenPilotDnf);

    const onOptionClickedPilotDnf = value => () => {
        setSelectedOptionPilotDnf(value);
        setIsOpenPilotDnf(false);
    };

    const [isOpenPilotDhl, setIsOpenPilotDhl] = useState(false);

    const togglingPilotDhl = () => setIsOpenPilotDhl(!isOpenPilotDhl);

    const onOptionClickedPilotDhl = value => () => {
        setSelectedOptionPilotDhl(value);
        setIsOpenPilotDhl(false);
    };

    useEffect(() => {
        obtenerPilotos();
    }, [])

    return (
        <div>
            {
                (concurso.nombre === 'GOAT')
                    ?
                        <div className='questions'>
                            <div className='item'>
                                <div className='item-title'>Posición 1</div>
                                <div className='question'>¿Quién quedará primero esta carrera?</div>
                                <div className='custom-select'>
                                    <div className='indication' onClick={togglingOne}>
                                        <span className='text'>{selectedPosOne || "Elige tu respuesta"}</span>
                                        <span className='arrow'><img src={arrow} alt='Arrow'/></span>
                                    </div>
                                    {posOneOpen && (
                                        <div className='options-wrapper'>
                                            <ul className='list-opts'>
                                                {pilotos.map(piloto => (
                                                    <li onClick={onOptionClickedOne(piloto.nombre + ' ' + piloto.apellido)} key={Math.random()}>
                                                        {piloto.nombre + ' ' + piloto.apellido}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='item'>
                                <div className='item-title'>Posición 2</div>
                                <div className='question'>¿Quién quedará segundo esta carrera?</div>
                                <div className='custom-select'>
                                    <div className='indication' onClick={togglingTwo}>
                                        <span className='text'>{selectedPosTwo || "Elige tu respuesta"}</span>
                                        <span className='arrow'><img src={arrow} alt='Arrow'/></span>
                                    </div>
                                    {posTwoOpen && (
                                        <div className='options-wrapper'>
                                            <ul className='list-opts'>
                                                {pilotos.map(piloto => (
                                                    <li onClick={onOptionClickedTwo(piloto.nombre + ' ' + piloto.apellido)} key={Math.random()}>
                                                        {piloto.nombre + ' ' + piloto.apellido}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='item'>
                                <div className='item-title'>Posición 3</div>
                                <div className='question'>¿Quién quedará tercero esta carrera?</div>
                                <div className='custom-select'>
                                    <div className='indication' onClick={togglingThree}>
                                        <span className='text'>{selectedPosThree || "Elige tu respuesta"}</span>
                                        <span className='arrow'><img src={arrow} alt='Arrow'/></span>
                                    </div>
                                    {posThreeOpen && (
                                        <div className='options-wrapper'>
                                            <ul className='list-opts'>
                                                {pilotos.map(piloto => (
                                                    <li onClick={onOptionClickedThree(piloto.nombre + ' ' + piloto.apellido)} key={Math.random()}>
                                                        {piloto.nombre + ' ' + piloto.apellido}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='item'>
                                <div className='item-title'>Posición 4</div>
                                <div className='question'>¿Quién quedará cuarto esta carrera?</div>
                                <div className='custom-select'>
                                    <div className='indication' onClick={togglingFour}>
                                        <span className='text'>{selectedPosFour || "Elige tu respuesta"}</span>
                                        <span className='arrow'><img src={arrow} alt='Arrow'/></span>
                                    </div>
                                    {posFourOpen && (
                                        <div className='options-wrapper'>
                                            <ul className='list-opts'>
                                                {pilotos.map(piloto => (
                                                    <li onClick={onOptionClickedFour(piloto.nombre + ' ' + piloto.apellido)} key={Math.random()}>
                                                        {piloto.nombre + ' ' + piloto.apellido}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='item'>
                                <div className='item-title'>Posición 5</div>
                                <div className='question'>¿Quién quedará quinto esta carrera?</div>
                                <div className='custom-select'>
                                    <div className='indication' onClick={togglingFive}>
                                        <span className='text'>{selectedPosFive || "Elige tu respuesta"}</span>
                                        <span className='arrow'><img src={arrow} alt='Arrow'/></span>
                                    </div>
                                    {posFiveOpen && (
                                        <div className='options-wrapper'>
                                            <ul className='list-opts'>
                                                {pilotos.map(piloto => (
                                                    <li onClick={onOptionClickedFive(piloto.nombre + ' ' + piloto.apellido)} key={Math.random()}>
                                                        {piloto.nombre + ' ' + piloto.apellido}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    :
                        <div className='questions'>
                            <div className='item'>
                                <div className='item-title'>Pregunta 1</div>
                                <div className='question'>¿En qué lugar quedará Sergio “Checo” Pérez  en esta carrera?</div>
                                <div className='custom-select'>
                                    <div className='indication' onClick={toggling}>
                                        <span className='text'>{selectedOption || "Elige tu respuesta"}</span>
                                        <span className='arrow'><img src={arrow} alt='Arrow'/></span>
                                    </div>
                                    {isOpen && (
                                        <div className='options-wrapper'>
                                            <ul className='list-opts'>
                                                {options.map(option => (
                                                    <li onClick={onOptionClicked(option)} key={Math.random()}>
                                                    {option}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='item'>
                                <div className='item-title'>Pregunta 2</div>
                                <div className='question'>¿Qué piloto se quedará con el DHL fastest lap award en esta carrera?</div>
                                <div className='custom-select'>
                                    <div className='indication' onClick={togglingPilotDhl}>
                                        <span className='text'>{selectedOptionPilotDhl || "Elige tu respuesta"}</span>
                                        <span className='arrow'><img src={arrow} alt='Arrow'/></span>
                                    </div>
                                    {isOpenPilotDhl && (
                                        <div className='options-wrapper'>
                                            <ul className='list-opts'>
                                                {pilotos.map(piloto => (
                                                    <li onClick={onOptionClickedPilotDhl(piloto.nombre + ' ' + piloto.apellido)} key={Math.random()}>
                                                        {piloto.nombre + ' ' + piloto.apellido}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='item'>
                                <div className='item-title'>Pregunta 3</div>
                                <div className='question'>¿Qué piloto será primero en abandonar DNF en esta carrera?</div>
                                <div className='custom-select'>
                                    <div className='indication' onClick={togglingPilotDnf}>
                                        <span className='text'>{selectedOptionPilotDnf || "Elige tu respuesta"}</span>
                                        <span className='arrow'><img src={arrow} alt='Arrow'/></span>
                                    </div>
                                    {isOpenPilotDnf && (
                                        <div className='options-wrapper'>
                                            <ul className='list-opts'>
                                                {pilotos.map(piloto => (
                                                    <li onClick={onOptionClickedPilotDnf(piloto.nombre + ' ' + piloto.apellido)} key={Math.random()}>
                                                        {piloto.nombre + ' ' + piloto.apellido}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
            }
        </div>
    );
}

export default Preguntas;