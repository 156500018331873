import {
    OBTENER_PARTICIPANTES,
    OBTENER_PARTICIPANTES_HOSPITALITY,
    OBTENER_PARTICIPANTES_WARM,
    OBTENER_PARTICIPANTES_BOX,
    OBTENER_PARTICIPANTES_PADDOCK,
    OBTENER_PARTICIPANTES_WOMEN,
    OBTENER_PARTICIPANTES_POR_USUARIO,
    AGREGAR_PARTICIPANTE,
    ACTUALIZAR_PARTICIPANTE
} from '../../types';

const reducer = (state, action) => {
switch(action.type) {
    case OBTENER_PARTICIPANTES:
        return {
            ...state,
            participantesInscritos: action.payload
        }
    case OBTENER_PARTICIPANTES_HOSPITALITY:
        return {
            ...state,
            participantesInscritosHospitality: action.payload
        }
    case OBTENER_PARTICIPANTES_WARM:
        return {
            ...state,
            participantesInscritosWarm: action.payload
        }
    case OBTENER_PARTICIPANTES_BOX:
        return {
            ...state,
            participantesInscritosBox: action.payload
        }
    case OBTENER_PARTICIPANTES_PADDOCK:
        return {
            ...state,
            participantesInscritosPaddock: action.payload
        }
    case OBTENER_PARTICIPANTES_WOMEN:
        return {
            ...state,
            participantesInscritosWomen: action.payload
        }
    case OBTENER_PARTICIPANTES_POR_USUARIO:
        return {
            ...state,
            participantesInscritosPorUsuario: action.payload
        }

    case AGREGAR_PARTICIPANTE:
        return {
            ...state,
            participantesInscritos: [...state.participantesInscritos, action.payload]
        }

    case ACTUALIZAR_PARTICIPANTE:
        return {
            ...state,
            participantesInscritos: state.participantesInscritos.map(participante => participante._id === action.payload._id ? action.payload : participante)
        }
    default:
        return state;
    }
}

export default reducer;