import React, {useState, useContext} from "react";
import { Link } from "react-router-dom";
import backIcon from "../../design/imgs/arrow-l-1.svg";
import styled from "styled-components";
import "../../design/styles/modal-races.scss";
import listaParticipantesContext from "../../context/listaParticipantes/listaParticipantesContext";
import Modal from "@material-ui/core/Modal";
import { DateTime } from "luxon"
import AuthContext from '../../context/autenticacion/authContext';

import speedway01 from "../../design/imgs/pista-01.jpg";
import speedway02 from "../../design/imgs/pista-02.jpg";
import speedway03 from "../../design/imgs/pista-03.jpg";
import speedway04 from "../../design/imgs/pista-04.jpg";
import speedway05 from "../../design/imgs/pista-05.jpg";
import speedway06 from "../../design/imgs/pista-06.jpg";
import speedway07 from "../../design/imgs/pista-07.jpg";
import speedway08 from "../../design/imgs/pista-08.jpg";
import speedway09 from "../../design/imgs/pista-09.jpg";
import speedway10 from "../../design/imgs/pista-10.jpg";
import speedway11 from "../../design/imgs/pista-11.jpg";
import speedway12 from "../../design/imgs/pista-12.jpg";
import speedway13 from "../../design/imgs/pista-13.jpg";
import speedway14 from "../../design/imgs/pista-14.jpg";
import speedway15 from "../../design/imgs/pista-15.jpg";
import speedway16 from "../../design/imgs/pista-16.jpg";
import speedway17 from "../../design/imgs/pista-17.jpg";
import speedway18 from "../../design/imgs/pista-18.jpg";
import speedway19 from "../../design/imgs/pista-19.jpg";
import speedway20 from "../../design/imgs/pista-20.jpg";
import speedway21 from "../../design/imgs/pista-21.jpg";
import speedway22 from "../../design/imgs/pista-22.jpg";

import flag01 from "../../design/imgs/flag-01.png";
import flag02 from "../../design/imgs/flag-02.png";
import flag03 from "../../design/imgs/flag-03.png";
import flag04 from "../../design/imgs/flag-04.png";
import flag05 from "../../design/imgs/flag-05.png";
import flag06 from "../../design/imgs/flag-06.png";
import flag07 from "../../design/imgs/flag-07.png";
import flag08 from "../../design/imgs/flag-08.png";
import flag09 from "../../design/imgs/flag-09.png";
import flag10 from "../../design/imgs/flag-10.png";
import flag11 from "../../design/imgs/flag-11.png";
import flag12 from "../../design/imgs/flag-12.png";
import flag13 from "../../design/imgs/flag-13.png";
import flag14 from "../../design/imgs/flag-14.png";
import flag15 from "../../design/imgs/flag-15.png";
import flag16 from "../../design/imgs/flag-16.png";
import flag17 from "../../design/imgs/flag-17.png";
import flag18 from "../../design/imgs/flag-18.png";
import flag19 from "../../design/imgs/flag-19.png";
import flag20 from "../../design/imgs/flag-20.png";
import flag21 from "../../design/imgs/flag-21.png";
import flag22 from "../../design/imgs/flag-22.png";

import map01 from "../../design/imgs/map-01.png";
import map02 from "../../design/imgs/map-02.png";
import map03 from "../../design/imgs/map-03.png";
import map04 from "../../design/imgs/map-04.png";
import map05 from "../../design/imgs/map-05.png";
import map06 from "../../design/imgs/map-06.png";
import map07 from "../../design/imgs/map-07.png";
import map08 from "../../design/imgs/map-08.png";
import map09 from "../../design/imgs/map-09.png";
import map10 from "../../design/imgs/map-10.png";
import map11 from "../../design/imgs/map-11.png";
import map12 from "../../design/imgs/map-12.png";
import map13 from "../../design/imgs/map-13.png";
import map14 from "../../design/imgs/map-14.png";
import map15 from "../../design/imgs/map-15.png";
import map16 from "../../design/imgs/map-16.png";
import map17 from "../../design/imgs/map-17.png";
import map18 from "../../design/imgs/map-18.png";
import map19 from "../../design/imgs/map-19.png";
import map20 from "../../design/imgs/map-20.png";
import map21 from "../../design/imgs/map-21.png";
import map22 from "../../design/imgs/map-22.png";

import modalBackground from "../../design/imgs/pop-up.jpg";
import close from "../../design/imgs/close.svg";
import closeHover from "../../design/imgs/close2.svg";

const Background = styled.div`
    background-image: url(${modalBackground});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;

const ListaCarreras = ({concurso, carreras}) => {

    // Extraer la información de autenticación
    const authContext = useContext(AuthContext);
    const {usuario} = authContext;

    // Obtener las tareas del proyecto
    const listaContext = useContext(listaParticipantesContext);
    const {obtenerParticipantes} = listaContext;

    // Configuración del modal de material-ui
    //const[modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        localStorage.removeItem("currentRace");
        localStorage.removeItem("index");
    }
    

    let currentRace = "",
        index = "",
        carrerasTerminadas = localStorage.getItem("carrerasTerminadas");
    if (localStorage.getItem("currentRace")) {
        currentRace = JSON.parse(localStorage.getItem("currentRace"));
        index = localStorage.getItem("index");
    }

    // crea un nuevo objeto `Date`
    var nowDay = DateTime.now().setZone("America/Mexico_City").setLocale("es").toFormat("dd"),
        nowMonth = DateTime.now().setZone("America/Mexico_City").setLocale("es").toFormat("LL"),
        nowYear = DateTime.now().setZone("America/Mexico_City").setLocale("es").toFormat("y"),
        nowDate = Date.parse(nowYear + '-' + nowMonth + '-' + nowDay),
        nowTimeHours = DateTime.now().setZone("America/Mexico_City").setLocale("es").toFormat("T"),
        nowTimeHoursFormated = Date.parse(nowYear + '-' + nowMonth + '-' + nowDay+'T'+nowTimeHours),
        dias = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
        diasCompletos = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        practice_one = new Date(currentRace.practica_1),
        practice_one_day = practice_one.getDay(),
        practice_two = new Date(currentRace.practica_2),
        practice_two_day = practice_two.getDay(),
        practice_three = new Date(currentRace.practica_3),
        practice_three_day = practice_three.getDay(),
        sprint_quali = new Date(currentRace.sprint_quali),
        sprint_quali_day = sprint_quali.getDay(),
        sprint_date = new Date(currentRace.sprint),
        sprint_day = sprint_date.getDay(),
        quali_date = new Date(currentRace.qualifying),
        quali_day = quali_date.getDay(),
        race_date = new Date(currentRace.carrera),
        race_day = race_date.getDay(),
        deadline_date = new Date(currentRace.deadline_fecha+'T00:00'),
        deadline_day = deadline_date.getDay(),
        deadline_hour = currentRace.deadline_hora;

    const cargaListaUsuarios = id => {
        obtenerParticipantes(id);
    }

    const setInfo = (begin, end, index) => {
        let beginDate = new Date(begin+'T00:00'),
            beginDay = beginDate.getDate(),
            beginMonth = beginDate.getMonth(),
            endDate = new Date(end+'T00:00'),
            endDay = endDate.getDate(),
            endMonth = endDate.getMonth(),
            meses = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'],
            allDateContainers = document.querySelectorAll('.date');

        allDateContainers.forEach(container => {
            let dataIndex = container.getAttribute('data-index');
            if(dataIndex == index && begin && end) {
                container.innerHTML = ((beginDay < 10) ? '0' : '') + beginDay + ' ' + meses[beginMonth] + ' - ' + ((endDay < 10) ? '0' : '') + endDay + ' ' + meses[endMonth]
            }
        })
    }

    return (
        <section className="block races-list">
            <img className="hidden" src={speedway01} alt="Imagen Pista"/>
            <img className="hidden" src={speedway02} alt="Imagen Pista"/>
            <img className="hidden" src={speedway03} alt="Imagen Pista"/>
            <img className="hidden" src={speedway04} alt="Imagen Pista"/>
            <img className="hidden" src={speedway05} alt="Imagen Pista"/>
            <img className="hidden" src={speedway06} alt="Imagen Pista"/>
            <img className="hidden" src={speedway07} alt="Imagen Pista"/>
            <img className="hidden" src={speedway08} alt="Imagen Pista"/>
            <img className="hidden" src={speedway09} alt="Imagen Pista"/>
            <img className="hidden" src={speedway10} alt="Imagen Pista"/>
            <img className="hidden" src={speedway11} alt="Imagen Pista"/>
            <img className="hidden" src={speedway12} alt="Imagen Pista"/>
            <img className="hidden" src={speedway13} alt="Imagen Pista"/>
            <img className="hidden" src={speedway14} alt="Imagen Pista"/>
            <img className="hidden" src={speedway15} alt="Imagen Pista"/>
            <img className="hidden" src={speedway16} alt="Imagen Pista"/>
            <img className="hidden" src={speedway17} alt="Imagen Pista"/>
            <img className="hidden" src={speedway18} alt="Imagen Pista"/>
            <img className="hidden" src={speedway19} alt="Imagen Pista"/>
            <img className="hidden" src={speedway20} alt="Imagen Pista"/>
            <img className="hidden" src={speedway21} alt="Imagen Pista"/>
            <img className="hidden" src={speedway22} alt="Imagen Pista"/>

            <img className="hidden" src={flag01} alt="Imagen Bandera"/>
            <img className="hidden" src={flag02} alt="Imagen Bandera"/>
            <img className="hidden" src={flag03} alt="Imagen Bandera"/>
            <img className="hidden" src={flag04} alt="Imagen Bandera"/>
            <img className="hidden" src={flag05} alt="Imagen Bandera"/>
            <img className="hidden" src={flag06} alt="Imagen Bandera"/>
            <img className="hidden" src={flag07} alt="Imagen Bandera"/>
            <img className="hidden" src={flag08} alt="Imagen Bandera"/>
            <img className="hidden" src={flag09} alt="Imagen Bandera"/>
            <img className="hidden" src={flag10} alt="Imagen Bandera"/>
            <img className="hidden" src={flag11} alt="Imagen Bandera"/>
            <img className="hidden" src={flag12} alt="Imagen Bandera"/>
            <img className="hidden" src={flag13} alt="Imagen Bandera"/>
            <img className="hidden" src={flag14} alt="Imagen Bandera"/>
            <img className="hidden" src={flag15} alt="Imagen Bandera"/>
            <img className="hidden" src={flag16} alt="Imagen Bandera"/>
            <img className="hidden" src={flag17} alt="Imagen Bandera"/>
            <img className="hidden" src={flag18} alt="Imagen Bandera"/>
            <img className="hidden" src={flag19} alt="Imagen Bandera"/>
            <img className="hidden" src={flag20} alt="Imagen Bandera"/>
            <img className="hidden" src={flag21} alt="Imagen Bandera"/>
            <img className="hidden" src={flag22} alt="Imagen Bandera"/>

            <img className="hidden" src={map01} alt="Imagen Mapa"/>
            <img className="hidden" src={map02} alt="Imagen Mapa"/>
            <img className="hidden" src={map03} alt="Imagen Mapa"/>
            <img className="hidden" src={map04} alt="Imagen Mapa"/>
            <img className="hidden" src={map05} alt="Imagen Mapa"/>
            <img className="hidden" src={map06} alt="Imagen Mapa"/>
            <img className="hidden" src={map07} alt="Imagen Mapa"/>
            <img className="hidden" src={map08} alt="Imagen Mapa"/>
            <img className="hidden" src={map09} alt="Imagen Mapa"/>
            <img className="hidden" src={map10} alt="Imagen Mapa"/>
            <img className="hidden" src={map11} alt="Imagen Mapa"/>
            <img className="hidden" src={map12} alt="Imagen Mapa"/>
            <img className="hidden" src={map13} alt="Imagen Mapa"/>
            <img className="hidden" src={map14} alt="Imagen Mapa"/>
            <img className="hidden" src={map15} alt="Imagen Mapa"/>
            <img className="hidden" src={map16} alt="Imagen Mapa"/>
            <img className="hidden" src={map17} alt="Imagen Mapa"/>
            <img className="hidden" src={map18} alt="Imagen Mapa"/>
            <img className="hidden" src={map19} alt="Imagen Mapa"/>
            <img className="hidden" src={map20} alt="Imagen Mapa"/>
            <img className="hidden" src={map21} alt="Imagen Mapa"/>
            <img className="hidden" src={map22} alt="Imagen Mapa"/>

            <div className="holder">
                <div className="container-fluid">
                    <div className="content">
                        {carreras.map((carrera, index) => (
                            <div className="race" key={index} onLoad={setInfo(carrera.date_begin, carrera.date_end, index)}>
                                <div className="race-date" id={"race-" + (index + 1)}>
                                    <div className="flex-content">
                                        <div className="left-side">
                                            <div className="index">{(index < 9) ? "0" + (index + 1) : (index + 1)}</div>
                                            <span className="flag">
                                                {
                                                    carrera.flag_src !== ''
                                                        ?
                                                            <img src={carrera.flag_src} alt="Bandera" />
                                                        :
                                                            null
                                                }
                                            </span>
                                            <div className="date" data-index={index}></div>
                                            <div
                                                onClick={() => {
                                                    handleOpen();
                                                    localStorage.setItem("currentRace", JSON.stringify(carrera));
                                                    localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                }}
                                                className="schedule"
                                                id={(carrera.active) ? "active" : null }
                                            >Horarios</div>
                                            {
                                                (index + 1 <= carrerasTerminadas)
                                                    ?
                                                        <Link
                                                            to={"resultados-carrera"}
                                                            className="race-results-mob visible-xs"
                                                            onClick={
                                                                () => {
                                                                    cargaListaUsuarios(concurso._id);
                                                                    localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                                }
                                                            }
                                                        >Ver resultados</Link>
                                                    :
                                                        (nowDate > Date.parse(carrera.deadline_fecha))
                                                            ?
                                                                <Link
                                                                    to={"resultados-carrera"}
                                                                    className="race-results-mob visible-xs"
                                                                    onClick={
                                                                        () => {
                                                                            cargaListaUsuarios(concurso._id);
                                                                            localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                                        }
                                                                    }
                                                                >Ver resultados</Link>
                                                            :
                                                                    (nowDate === Date.parse(carrera.deadline_fecha))
                                                                        ?
                                                                            (nowTimeHoursFormated > Date.parse(carrera.deadline_fecha+'T'+carrera.deadline_hora))
                                                                                ?
                                                                                    <Link
                                                                                        to={"resultados-carrera"}
                                                                                        className="race-results-mob visible-xs"
                                                                                        onClick={
                                                                                            () => {
                                                                                                cargaListaUsuarios(concurso._id);
                                                                                                localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                                                            }
                                                                                        }
                                                                                    >Ver resultados</Link>
                                                                                :
                                                                                    <Link
                                                                                        to={"estrategia-carrera"}
                                                                                        className="strategy-mob visible-xs"
                                                                                        id={(carrera.active) ? "active" : null }
                                                                                        onClick={() => {
                                                                                            handleOpen();
                                                                                            localStorage.setItem("currentRace", JSON.stringify(carrera));
                                                                                            localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                                                        }}
                                                                                    >Estrategia</Link>
                                                                        :
                                                                            <Link
                                                                                to={"estrategia-carrera"}
                                                                                className="strategy-mob visible-xs"
                                                                                id={(carrera.active) ? "active" : null }
                                                                                onClick={() => {
                                                                                    handleOpen();
                                                                                    localStorage.setItem("currentRace", JSON.stringify(carrera));
                                                                                    localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                                                }}
                                                                            >Estrategia</Link>
                                            }
                                        </div>
                                        <div className="right-side">
                                            <div className="country">{carrera.pais}</div>
                                            <div className="city">{carrera.ciudad}</div>
                                        </div>
                                    </div>
                                        {
                                            (usuario.email === 'david7_qh@hotmail.com' || usuario.email === 'jdecolombres_11@hotmail.com' || usuario.email === 'alvarordlp@gmail.com')
                                                ?
                                                    <Link
                                                        to={"resultados-carrera"}
                                                        className="race-results"
                                                        onClick={
                                                            () => {
                                                                cargaListaUsuarios(concurso._id);
                                                                localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                                localStorage.setItem("currentRace", JSON.stringify(carrera));
                                                            }
                                                        }
                                                    >Ver tablero</Link>
                                                :
                                                    null
                                        }
                                </div>
                                <div className="race-info hidden-xs">
                                    <div className="race-data">
                                        <div className="flex-content">
                                            <div className="width-fix">
                                                <span>Primer Grand Prix</span>
                                                <b>{carrera.primer_premio}</b>
                                            </div>
                                            <div className="ml">
                                                <span>Número de vueltas</span>
                                                <b>{carrera.no_vueltas}</b>
                                            </div>
                                        </div>
                                        <div className="flex-content">
                                            <div className="width-fix">
                                                <span>Longitud del circuito</span>
                                                <b>{carrera.longitud_circuito}<i>km</i></b>
                                            </div>
                                            <div className="ml">
                                                <span>Distancia de carrera</span>
                                                <b>{carrera.distancia_carrera}<i>km</i></b>
                                            </div>
                                        </div>
                                        <div>
                                            <span>Registro de vuelta</span>
                                            <div className="flex-content">
                                                <div><b>{carrera.registro_vuelta}</b></div>
                                                <div><i>{carrera.registro_piloto}</i></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="race-map">
                                            <div className="map"><img src={carrera.map_src} alt="Mapa" /></div>
                                        </div>
                                        {/*
                                            (usuario.email === 'david7_qh@hotmail.com' || usuario.email === 'jdecolombres_11@hotmail.com' || usuario.email === 'alvarordlp@gmail.com')
                                                ?
                                                    <Link
                                                        to={"resultados-carrera"}
                                                        className="race-results"
                                                        onClick={
                                                            () => {
                                                                cargaListaUsuarios(concurso._id);
                                                                localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                                localStorage.setItem("currentRace", JSON.stringify(carrera));
                                                            }
                                                        }
                                                    >Ver tablero</Link>
                                                :
                                                    null
                                        */}
                                        {
                                            (index + 1 <= carrerasTerminadas)
                                                ?
                                                    <Link
                                                        to={"resultados-carrera"}
                                                        className="race-results"
                                                        onClick={
                                                            () => {
                                                                cargaListaUsuarios(concurso._id);
                                                                localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                            }
                                                        }
                                                    >Ver resultados</Link>
                                                :
                                                    (nowDate > Date.parse(carrera.deadline_fecha))
                                                        ?
                                                            <Link
                                                                to={"resultados-carrera"}
                                                                className="race-results"
                                                                onClick={
                                                                    () => {
                                                                        cargaListaUsuarios(concurso._id);
                                                                        localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                                    }
                                                                }
                                                            >Ver resultados</Link>
                                                        :
                                                                (nowDate === Date.parse(carrera.deadline_fecha))
                                                                    ?
                                                                        (nowTimeHoursFormated > Date.parse(carrera.deadline_fecha+'T'+carrera.deadline_hora))
                                                                            ?
                                                                                <Link
                                                                                    to={"resultados-carrera"}
                                                                                    className="race-results"
                                                                                    onClick={
                                                                                        () => {
                                                                                            cargaListaUsuarios(concurso._id);
                                                                                            localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                                                        }
                                                                                    }
                                                                                >Ver resultados</Link>
                                                                            :
                                                                                <Link
                                                                                    to={"estrategia-carrera"}
                                                                                    className="strategy"
                                                                                    id={(carrera.active) ? "active" : null }
                                                                                    onClick={() => {
                                                                                        handleOpen();
                                                                                        localStorage.setItem("currentRace", JSON.stringify(carrera));
                                                                                        localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                                                    }}
                                                                                >Estrategia</Link>
                                                                    :
                                                                        <Link
                                                                            to={"estrategia-carrera"}
                                                                            className="strategy"
                                                                            id={(carrera.active) ? "active" : null }
                                                                            onClick={() => {
                                                                                handleOpen();
                                                                                localStorage.setItem("currentRace", JSON.stringify(carrera));
                                                                                localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                                            }}
                                                                        >Estrategia</Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}
                        <Modal
                            open={open}
                            onClose={() => {
                                handleClose()
                            }}
                        >
                        <div className="pl-modal-races">
                            <div
                                className="close-modal"
                                onClick={() => {
                                    handleClose();
                                }}
                            >
                                <img className="icon-normal" src={close} alt="Botón Cerrar Modal"/>
                                <img className="icon-hover" src={closeHover} alt="Botón Cerrar Modal"/>
                            </div>
                            <Background/>
                            {
                                (currentRace.tipo_carrera === 'Sprint')
                                    ?
                                        <div className="holder">
                                            <div className="container-fluid">
                                                <div className="header">
                                                    <div className="title">Horarios</div>
                                                    <div className="desc">{concurso.nombre} - {index} · {currentRace.pais} · {currentRace.ciudad}</div>
                                                </div>
                                                <div className="content">
                                                    <div className="flex-content">
                                                        <div className="left">Práctica 1<span> {dias[practice_one_day]}</span></div>
                                                        <div className="right">{practice_one.getHours() + ':' + practice_one.getMinutes() + ((practice_one.getMinutes() == '0') ? '0' : '')}</div>
                                                    </div><br/>
                                                    <div className="advertising">Dead-line para selección de estrategia: { diasCompletos[deadline_day] + ' ' + deadline_hour }</div>
                                                    <div className="flex-content">
                                                        <div className="left">Sprint Quali<span> {dias[sprint_quali_day]}</span></div>
                                                        <div className="right">{sprint_quali.getHours() + ':' + sprint_quali.getMinutes() + ((sprint_quali.getMinutes() == '0') ? '0' : '')}</div>
                                                    </div><br/>
                                                    <div className="flex-content">
                                                        <div className="left">Sprint<span> {dias[sprint_day]}</span></div>
                                                        <div className="right">{sprint_date.getHours() + ':' + sprint_date.getMinutes() + ((sprint_date.getMinutes() == '0') ? '0' : '')}</div>
                                                    </div>
                                                    <div className="flex-content">
                                                        <div className="left">Qualifying<span> {dias[quali_day]}</span></div>
                                                        <div className="right">{quali_date.getHours() + ':' + quali_date.getMinutes() + ((quali_date.getMinutes() == '0') ? '0' : '')}</div>
                                                    </div><br/>
                                                    <div className="flex-content">
                                                        <div className="left"><b>Carrera<span> {dias[race_day]}</span></b></div>
                                                        <div className="right"><b>{race_date.getHours() + ':' + race_date.getMinutes() + ((race_date.getMinutes() == '0') ? '0' : '')}</b></div>
                                                    </div>
                                                    <div
                                                        className="accept"
                                                        onClick={() => {
                                                            handleClose();
                                                    }}
                                                    >Aceptar</div>
                                                </div> 
                                            </div>
                                        </div>
                                    :
                                        <div className="holder">
                                            <div className="container-fluid">
                                                <div className="header">
                                                    <div className="title">Horarios</div>
                                                    <div className="desc">{concurso.nombre} - {index} · {currentRace.pais} · {currentRace.ciudad}</div>
                                                </div>
                                                <div className="content">
                                                    <div className="flex-content">
                                                        <div className="left">Práctica 1<span> {dias[practice_one_day]}</span></div>
                                                        <div className="right">{practice_one.getHours() + ':' + practice_one.getMinutes() + ((practice_one.getMinutes() == '0') ? '0' : '')}</div>
                                                    </div><br/>
                                                    <div className="flex-content">
                                                        <div className="left">Práctica 2<span> {dias[practice_two_day]}</span></div>
                                                        <div className="right">{practice_two.getHours() + ':' + practice_two.getMinutes() + ((practice_two.getMinutes() == '0') ? '0' : '')}</div>
                                                    </div><br/>
                                                    <div className="flex-content">
                                                        <div className="left">Práctica 3<span> {dias[practice_three_day]}</span></div>
                                                        <div className="right">{practice_three.getHours() + ':' + practice_three.getMinutes() + ((practice_three.getMinutes() == '0') ? '0' : '')}</div>
                                                    </div>
                                                    <div className="advertising">Dead-line para selección de estrategia: { diasCompletos[deadline_day] + ' ' + deadline_hour }</div>
                                                    <div className="flex-content">
                                                        <div className="left">Qualifying<span> {dias[quali_day]}</span></div>
                                                        <div className="right">{quali_date.getHours() + ':' + quali_date.getMinutes() + ((quali_date.getMinutes() == '0') ? '0' : '')}</div>
                                                    </div><br/>
                                                    <div className="flex-content">
                                                        <div className="left"><b>Carrera<span> {dias[race_day]}</span></b></div>
                                                        <div className="right"><b>{race_date.getHours() + ':' + race_date.getMinutes() + ((race_date.getMinutes() == '0') ? '0' : '')}</b></div>
                                                    </div>
                                                    <div
                                                        className="accept"
                                                        onClick={() => {
                                                            handleClose();
                                                    }}
                                                    >Aceptar</div>
                                                </div>
                                            </div>
                                        </div>
                            }
                        </div>
                        </Modal>
                    </div>
                </div>
            </div>
            <Link to={"/dashboard"} className="back visible-xs">
                <div className="icon">
                    <img
                        src={backIcon}
                        alt="Regresar"
                    />
                </div>
                <div className="text">Regresar</div>
            </Link>
        </section>
    );
}

export default ListaCarreras;