export const VALIDAR_FORMULARIO = 'VALIDAR_FORMULARIO';

export const MOSTRAR_ALERTA = 'MOSTRAR_ALERTA';
export const OCULTAR_ALERTA = 'OCULTAR_ALERTA';

export const REGISTRO_EXITOSO = 'REGISTRO_EXITOSO';
export const REGISTRO_ERROR = 'REGISTRO_ERROR';
export const REGISTRO_PASSWORD = 'REGISTRO_PASSWORD';
export const OBTENER_USUARIO = 'OBTENER_USUARIO';
export const ACTUALIZAR_USUARIO = 'ACTUALIZAR_USUARIO';
export const ACTUALIZAR_USUARIO_POR_EMAIL = 'ACTUALIZAR_USUARIO_POR_EMAIL';
export const LOGIN_EXITOSO = 'LOGIN_EXITOSO';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const CERRAR_SESION = 'CERRAR_SESION';

export const OBTENER_CONCURSOS = 'OBTENER_CONCURSOS';
export const OBTENER_CONCURSOS_POR_ID = 'OBTENER_CONCURSOS_POR_ID';
export const LISTA_PARTICIPANTES = 'LISTA_PARTICIPANTES';
export const CONCURSO_ACTUAL = 'CONCURSO_ACTUAL';

export const OBTENER_PARTICIPANTES = 'OBTENER_PARTICIPANTES';
export const OBTENER_PARTICIPANTES_HOSPITALITY = 'OBTENER_PARTICIPANTES_HOSPITALITY';
export const OBTENER_PARTICIPANTES_WARM = 'OBTENER_PARTICIPANTES_WARM';
export const OBTENER_PARTICIPANTES_BOX = 'OBTENER_PARTICIPANTES_BOX';
export const OBTENER_PARTICIPANTES_PADDOCK = 'OBTENER_PARTICIPANTES_PADDOCK';
export const OBTENER_PARTICIPANTES_WOMEN = 'OBTENER_PARTICIPANTES_WOMEN';
export const OBTENER_PARTICIPANTES_POR_USUARIO = 'OBTENER_PARTICIPANTES_POR_USUARIO';
export const AGREGAR_PARTICIPANTE = 'AGREGAR_PARTICIPANTE';

export const OBTENER_PILOTOS = 'OBTENER_PILOTOS';

export const ACTUALIZAR_PARTICIPANTE = 'ACTUALIZAR_PARTICIPANTE';

export const OBTENER_CARRERAS = 'OBTENER_CARRERAS';
export const ACTUALIZAR_CARRERA = 'ACTUALIZAR_CARRERA';