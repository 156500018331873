import React, { Fragment, useContext, useEffect } from 'react';
import listaCarrerasContext from "../../context/listaCarreras/listaCarrerasContext";
import MainNavigation from '../partials/MainNavigation';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import sideBar from '../../design/imgs/barra-lateral.jpg';

const Races = () => {
    // crea un nuevo objeto `Date`
    var today = new Date();
    var nowDate = today.getDate();
    var nowTime = today.getHours();

    const SideBarBackground = styled.div`
        background-image: url(${sideBar});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    `;

    // Obtener las carreras
    const carrerasContext = useContext(listaCarrerasContext);
    const {carreras, obtenerCarreras} = carrerasContext;

    useEffect(() => {
        obtenerCarreras();
    }, [])

    return (
        <div className='outer-wrapper'>
            <MainNavigation/>
            <div className='races-wrapper'>
                <section className='block sideBar-races'>
                    <SideBarBackground/>
                    <div className='holder'>
                        <div className='content'>
                        </div>
                    </div>
                </section>
                <section className="block races-list">

                    <div className="holder">
                        <div className="container-fluid">
                            <div className="content">
                                {carreras.map((carrera, index) => (
                                    <div className="race" key={carrera.pais}>
                                        <div className="right-side">
                                            <div className="country">{carrera.pais} - {carrera.ciudad}</div>
                                                        <Fragment>
                                                            <Link
                                                                to={"/sorteo"}
                                                                className="gplk-link"
                                                                onClick={() => {
                                                                    localStorage.setItem("currentRace", JSON.stringify(carrera));
                                                                    localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                                }}
                                                            >
                                                                <div className="text">Cargar Sorteo</div>
                                                            </Link>
                                                            <Link
                                                                to={"/estrategia"}
                                                                className="gplk-link"
                                                                onClick={() => {
                                                                    localStorage.setItem("currentRace", JSON.stringify(carrera));
                                                                    localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                                }}
                                                            >
                                                                <div className="text">Cargar Estrategia</div>
                                                            </Link>
                                                            <Link
                                                                to={"/activacion-carrera"}
                                                                className="gplk-link"
                                                                onClick={() => {
                                                                    localStorage.setItem("currentRace", JSON.stringify(carrera));
                                                                    localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                                }}
                                                            >
                                                                <div className="text">Activar Carrera</div>
                                                            </Link>
                                                        </Fragment>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <Link to={"/dashboard"} className="back">
                        <div className="text">Regresar</div>
                    </Link>
                </section>
            </div>
        </div>
    )
}

export default Races;