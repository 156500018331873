import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Login from './components/auth/Login';
import Password from './components/auth/Password';
import NuevaCuenta from './components/auth/NuevaCuenta';
import Home from './components/home/Home';
import Dashboard from './components/dashboard/Dashboard';
import Profile from './components/profile/Profile';
import Rules from './components/rules/Rules';
import Contact from './components/contact/Contact';
import Credits from './components/contact/Credits';
import Carreras from './components/carreras/Carreras';
import OverallTable from './components/overallTable/OverallTable';
import ResultadoCarrera from './components/carreras/ResultadoCarrera';
import EstrategiaCarrera from './components/estrategias/EstrategiaCarrera';

import './design/styles/base.scss';
import socialImage from './design/imgs/social.jpg';

import ConcursoState from './context/concursos/concursoState';
import ListaParticipantesState from './context/listaParticipantes/listaParticipantesState';
import ListaPilotosState from './context/listaPilotos/listaPilotosState';
import ListaCarrerasState from './context/listaCarreras/listaCarrerasState';
import AlertaState from './context/alertas/alertaState';
import AuthState from './context/autenticacion/authState';
import tokenAuth from './config/token';
import RutaPrivada from './components/rutas/RutaPrivada';
import Races from './components/admin/Races';
import Estrategia from './components/admin/Estrategia';
import Sorteo from './components/admin/Sorteo';
import ActivarCarrera from './components/admin/ActivarCarrera';

// Revisar si tenemos un token
const token = localStorage.getItem('token');
if(token) {
  tokenAuth(token);
}

function App() {
  return (
    <AlertaState>
      <AuthState>
        <ConcursoState>
          <ListaParticipantesState>
            <ListaCarrerasState>
              <ListaPilotosState>
                <Router>
                  <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/login" component={Login}/>
                    <Route exact path="/recuperar-contrasena" component={Password}/>
                    <Route exact path="/nueva-cuenta" component={NuevaCuenta}/>
                    <RutaPrivada exact path="/dashboard" component={Dashboard}/>
                    <RutaPrivada exact path="/perfil" component={Profile}/>
                    <RutaPrivada exact path="/reglas" component={Rules}/>
                    <RutaPrivada exact path="/creditos" component={Credits}/>
                    <RutaPrivada exact path="/carreras" component={Carreras}/>
                    <RutaPrivada exact path="/tabla-general" component={OverallTable}/>
                    <RutaPrivada exact path="/resultados-carrera" component={ResultadoCarrera}/>
                    <RutaPrivada exact path="/estrategia-carrera" component={EstrategiaCarrera}/>
                    <Route exact path="/contacto" component={Contact}/>
                    <Route exact path="/administrador" component={Races}/>
                    <RutaPrivada exact path="/sorteo" component={Sorteo}/>
                    <RutaPrivada exact path="/estrategia" component={Estrategia}/>
                    <RutaPrivada exact path="/activacion-carrera" component={ActivarCarrera}/>
                  </Switch>
                </Router>
              </ListaPilotosState>
            </ListaCarrerasState>
          </ListaParticipantesState>
        </ConcursoState>
      </AuthState>
    </AlertaState>
  );
}

export default App;
