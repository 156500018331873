import React, {useContext, useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import AlertaContext from '../../context/alertas/alertaContext';
import AuthContext from '../../context/autenticacion/authContext';
import imagen from '../../design/imgs/registro.jpg';

// Import styles
import '../../design/styles/register.scss';

// Import partial
import FooterHome from '../partials/FooterRegister';

const NuevaCuenta = (props) => {

    //extraer los valores del context
    const alertaContext = useContext(AlertaContext);
    const {alerta, mostrarAlerta} = alertaContext;

    const authContext = useContext(AuthContext);
    const {mensaje, autenticado, registrarUsuario} = authContext;

    // En caso de que el usuario se haya autenticado o registrado o sea un registro duplicado
    useEffect(() => {
        if(autenticado) {
            props.history.push('/dashboard');
        }

        if (mensaje) {
            mostrarAlerta(mensaje.msg, mensaje.categoria);
        }
        // eslint-disable-next-line
    }, [mensaje, autenticado, props.history]);

    //State para iniciar sesión
    const [usuario, guardarUsuario] = useState({
        nombre: '',
        apellidos: '',
        email: '',
        telefono: '',
        password: '',
        confirmar: ''
    });

    // Extrar de usuario
    const {nombre, apellidos, email, telefono, password, confirmar} = usuario;

    const onChange = e => {
        guardarUsuario({
            ...usuario,
            [e.target.name] : [e.target.value]
        });
    };

    // Cuando el usuario quiere iniciar sesión
    const onSubmit = e => {
        e.preventDefault();

        // validar que no haya campos vacíos
        if(nombre.toString().trim() === '' || apellidos.toString().trim() === '' || email.toString().trim() === '' || telefono.toString().trim() === '' || password.toString().trim() === '' || confirmar.toString().trim() === '') {
            mostrarAlerta('Todos los campos son obligatorios', 'alerta-error');
            return;
        }

        // Password mínimo de 6 caracteres
        
        if(password.toString().length < 6) {
            mostrarAlerta('El password debe ser de al menos 6 caracteres', 'alerta-error');
            return;
        }

        // Los dos passwords son iguales
        if (password.toString() !== confirmar.toString()) {
            mostrarAlerta('Los passwords no son iguales', 'alerta-error');
            return;
        }

        // Pasarlo al action
        registrarUsuario({
            nombre: nombre.toString(),
            apellidos: apellidos.toString(),
            email: email.toString(),
            telefono: telefono.toString(),
            password: password.toString(),
            creditos: "0"
        });
    }

    return (
        <div className='outer-wrapper'>
            {alerta ? (<div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>) : null}
            <section className="block register" id="registro">
                <div className="background"></div>
                <div className="holder">
                    <div className="container-fluid">
                        <div className="header">
                            <div className="title">Registro</div>
                            <div className="desc"><b>¡Es momento de crear tu súper licencia!</b>Por favor, llena este registro con la información solicitada, de esta forma podremos generar tu perfil y así enciendas motores.</div>
                        </div>
                        <div className="content">
                            <div className="flex-content">
                                <div className="image-wrapper hidden-xs">
                                    <div className="image">
                                        <img src={imagen} alt="" />
                                    </div>
                                </div>
                                <div className="form-wrapper">
                                    <form
                                        onSubmit={onSubmit}
                                    >
                                        <div className="flex-input">
                                            <div className="input-container input-container-small">
                                                <input
                                                    type='text'
                                                    id='nombre'
                                                    name='nombre'
                                                    value={nombre}
                                                    onChange={onChange}
                                                />
                                                <label htmlFor="name">Nombre*</label>
                                            </div>
                                            <div className="input-container input-container-large">
                                                <input
                                                    type="text"
                                                    id="lastname"
                                                    name='apellidos'
                                                    value={apellidos}
                                                    onChange={onChange}
                                                />
                                                <label htmlFor="lastname">Apellidos*</label>
                                            </div>
                                        </div>
                                        <div className="flex-input">
                                            <div className="input-container input-container-small">
                                                <input
                                                    type='email'
                                                    id='email'
                                                    name='email'
                                                    placeholder='Tu email'
                                                    value={email}
                                                    onChange={onChange}
                                                />
                                                <label htmlFor="email">Correo*</label>
                                            </div>
                                            <div className="input-container input-container-large">
                                                <input
                                                    type="text"
                                                    id="phone"
                                                    name='telefono'
                                                    value={telefono}
                                                    onChange={onChange}
                                                />
                                                <label htmlFor="phone">Teléfono 10 dígitos*</label>
                                            </div>
                                        </div>
                                        <div className="flex-input">
                                            <div className="input-container input-container-small">
                                                <div className="input-group">
                                                    <input
                                                        type='password'
                                                        id='password'
                                                        name='password'
                                                        placeholder='Tu Password'
                                                        value={password}
                                                        onChange={onChange}
                                                    />
                                                </div>
                                                <label htmlFor="password">Escribe una contraseña*</label>
                                            </div>
                                            <div className="input-container input-container-large">
                                                <div className="input-group">
                                                    <input
                                                        type='password'
                                                        id='confirmar'
                                                        name='confirmar'
                                                        placeholder='Repite Tu Password'
                                                        value={confirmar}
                                                        onChange={onChange}
                                                    />
                                                </div>
                                                <label htmlFor="confirm-password">Confirmar contraseña*</label>
                                            </div>
                                        </div>
                                        <div className="flex-input-btns">
                                            <Link to={'/'} className="cancel">Cancelar</Link>
                                            <input
                                                type='submit'
                                                className='register'
                                                value='Registrarse'
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterHome />
        </div>
    );
}

export default NuevaCuenta;