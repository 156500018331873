import React, {useState, useContext, useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import AlertaContext from '../../context/alertas/alertaContext';
import AuthContext from '../../context/autenticacion/authContext';
import emailjs from '@emailjs/browser';

// Import styles
import backIcon from '../../design/imgs/arrow-l-1.svg';
import contactImage from '../../design/imgs/contacto.jpg'
import '../../design/styles/contact.scss';
import '../../design/styles/states-contact.scss';

// Import Partial
import FooterHome from '../partials/FooterRegister';

const Contact = () => {

    //extraer los valores del context
    const alertaContext = useContext(AlertaContext);
    const {alerta, mostrarAlerta} = alertaContext;

    // Extraer la información de autenticación
    const authContext = useContext(AuthContext);
    const {usuario, usuarioAutenticado} = authContext;

    useEffect(() => {
        usuarioAutenticado();
        // eslint-disable-next-line
    }, [])

    const form = useRef();

    // state para mandar correo
    const [infoCorreo, guardarInfoCorreo] = useState({
        nombre: '',
        correo: '',
        telefono: '',
        mensaje: ''
    });

    // Extrar de infoCorreo
    const {nombre, correo, telefono, mensaje} = infoCorreo;

    const onChange = e => {
        guardarInfoCorreo({
            ...infoCorreo,
            [e.target.name] : [e.target.value]
        });
    };

    const reset = () => {
        guardarInfoCorreo({
            nombre: '',
            correo: '',
            telefono: '',
            mensaje: ''
        });
    };

    const sendEmail = (e) => {
        e.preventDefault();

        // validar que no haya campos vacíos
        if(nombre.toString().trim() === '' || correo.toString().trim() === '' || telefono.toString().trim() === '' || mensaje.toString().trim() === '' ) {
            mostrarAlerta('Favor de llenar los campos obligatorios', 'alerta-error');
            return;
        }

        let statusMessage = document.querySelector('.status-message'),
            wrapperForm = document.querySelector('.wrapper-content-form');

        statusMessage.classList.remove('hidden');
        wrapperForm.classList.add('partial-hide');
        statusMessage.innerHTML = 
        `<div class="sending">
            <div class="message">
                <div class="icon icon-sending"></div>
                <div class="text">Enviando mensaje...</div>
            </div>
        </div>`;

        emailjs.sendForm('service_t9pd4vm', 'template_nxvj4j6', form.current, 'H086lp0RHCmGPEA91')
            .then((result) => {
                setTimeout(() => {
                    statusMessage.innerHTML = 
                    `<div class="success">
                        <div class="message">
                            <div class="icon icon-success"></div>
                            <div class="text">¡Mensaje enviado!</div>
                        </div>
                    </div>`;
                    console.log(result.text);
                }, 3000);
            }, (error) => {
                setTimeout(() => {
                    statusMessage.innerHTML = '<div class="error">Hubo un error, inténtalo más tarde.</div>';
                    console.log(error.text);
                }, 3000);
            });
        
        setTimeout(() => {

            statusMessage.classList.add('hidden');
            wrapperForm.classList.remove('partial-hide');

            e.target.reset();
            reset();
        }, 12000);
    }

    return (

        <div className='outer-wrapper'>
            {alerta ? (<div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>) : null}
            <section className='block contact'>
                <div className='background'></div>
                <div className='holder'>
                    <div className='container-fluid'>
                        <div className='header'>
                            <div className='title'>Contacto</div>
                            <div className='desc'><b>¿Necesitas ayuda o tienes alguna pregunta?</b> Contacta a tu ingeniero de carrera llenando la siguiente información y envíale un mensaje, está para ayudarte con lo que necesites.</div>
                        </div>
                        <div className='content'>
                            <div className='flex-content'>
                                <div className='image-wrapper hidden-xs'>
                                    <div className='image'>
                                        <img
                                            src={contactImage}
                                            alt="Imagen Contacto"
                                        />
                                    </div>
                                </div>
                                <div className='form-wrapper'>
                                    <form
                                        ref={form}
                                        onSubmit={sendEmail}
                                    >
                                        <div className="status-message hidden"></div>
                                        <div className="wrapper-content-form">
                                            <div className='flex-content'>
                                                <div className='input-container-small'>
                                                    <input
                                                        id='name'
                                                        name='nombre'
                                                        type='text'
                                                        data-validate='notEmpty'
                                                        data-placeholder=''
                                                        placeholder=''
                                                        value={nombre}
                                                        onChange={onChange}
                                                    />
                                                    <label htmlFor='name'>Nombre*</label>
                                                </div>
                                                <div className='input-container-large'>
                                                    <input
                                                        id='nickName'
                                                        name='nickname'
                                                        type='text'
                                                    />
                                                    <label htmlFor='nickName'>Nickname</label>
                                                </div>
                                            </div>
                                            <div className='flex-content'>
                                                <div className='inputs-wrapper'>
                                                    <div className='input-container'>
                                                        <input
                                                            id='email'
                                                            type='text'
                                                            name='correo'
                                                            data-validate='email'
                                                            data-placeholder=''
                                                            placeholder=''
                                                            value={correo}
                                                            onChange={onChange}
                                                        />
                                                        <label htmlFor='email'>Correo*</label>
                                                    </div>
                                                    <div className='input-container'>
                                                        <input
                                                            id='phone'
                                                            type='text'
                                                            name='telefono'
                                                            data-validate='phone'
                                                            data-placeholder=''
                                                            placeholder=''
                                                            value={telefono}
                                                            onChange={onChange}
                                                        />
                                                        <label htmlFor='phone'>Teléfono 10 dígitos*</label>
                                                    </div>
                                                </div>
                                                <div className='input-container-large textarea-container'>
                                                    <textarea
                                                        id='message'
                                                        name='mensaje'
                                                        value={mensaje}
                                                        onChange={onChange}
                                                    />
                                                    <label htmlFor='message'>Mensaje*</label>
                                                </div>
                                            </div>
                                            <input type='submit' value='Enviar' />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                {usuario
                    ?
                        <Link to={'/dashboard'} className='back'>
                            <div className='icon'>
                                <img
                                    src={backIcon}
                                    alt="Regresar"
                                />
                            </div>
                            <div className='text'>Regresar</div>
                        </Link>
                    :
                        <Link to={'/'} className='back'>
                            <div className='icon'>
                                <img
                                    src={backIcon}
                                    alt="Regresar"
                                />
                            </div>
                            <div className='text'>Regresar</div>
                        </Link>
                }
            </section>
            <FooterHome/>
        </div>
    );
}

export default Contact;