import {
    OBTENER_CONCURSOS,
    OBTENER_CONCURSOS_POR_ID,
    LISTA_PARTICIPANTES,
    CONCURSO_ACTUAL
} from '../../types';

const reducer = (state, action) => {
switch(action.type) {
    case OBTENER_CONCURSOS:
        return {
            ...state,
            concursos: action.payload
        }
    case OBTENER_CONCURSOS_POR_ID:
        return {
            ...state,
            concursosPorId: [...state.concursosPorId, action.payload]
        }
    case LISTA_PARTICIPANTES:
        return {
            ...state,
            listaParticipantes: true
        }
    case CONCURSO_ACTUAL:
        return {
            ...state,
            concurso: state.concursos.filter(concurso => concurso._id === action.payload)
        }
    default:
        return state;
    }
}

export default reducer;