import React, { useState, useContext } from 'react'
import MainNavigation from '../partials/MainNavigation';
import { Link } from 'react-router-dom';
import '../../design/styles/activate-race.scss';
import listaCarrerasContext from '../../context/listaCarreras/listaCarrerasContext';
import AlertaContext from '../../context/alertas/alertaContext';
import { DateTime } from "luxon"

const ActivarCarrera = () => {

    // Obtener las tareas del proyecto
    const carrerasContext = useContext(listaCarrerasContext);
    const {actualizarCarrera} = carrerasContext;

    //extraer los valores del context
    const alertaContext = useContext(AlertaContext);
    const {alerta, mostrarAlerta} = alertaContext;

    let index = localStorage.getItem('index');
    let currentRace = JSON.parse(localStorage.getItem('currentRace'));

    const [typeRace, setTypeRace] = useState('');
    const [dateRaceBegin, setDateRaceBegin] = useState('');
    const [dateRaceEnd, setDateRaceEnd] = useState('');
    const [hourRace, setHourRace] = useState('');
    const [practice_one, setPractice_one] = useState('');
    const [practice_two, setPractice_two] = useState('');
    const [practice_three, setPractice_three] = useState('');
    const [sprintQuali, setSprintQuali] = useState('');
    const [sprint, setSprint] = useState('');
    const [qualifying, setQualifying] = useState('');
    const [dayDeadline, setDayDeadline] = useState('');
    const [hourDeadline, setHourDeadline] = useState('');
    const [active, setActive] = useState('');

    const nuevaCarrera = {
        _id: currentRace._id,
        tipo_carrera: typeRace,
        date_begin: dateRaceBegin,
        date_end: dateRaceEnd,
        carrera: hourRace,
        practica_1: practice_one,
        practica_2: practice_two,
        practica_3: practice_three,
        sprint_quali: sprintQuali,
        sprint: sprint,
        qualifying: qualifying,
        deadline_fecha: dayDeadline,
        deadline_hora: hourDeadline,
        active: active
    }

    const handleSubmit = (ev) => {
        ev.preventDefault()

        /*var nowDay = DateTime.now().setZone("America/Mexico_City").setLocale("es").toFormat("dd"),
            nowMonth = DateTime.now().setZone("America/Mexico_City").setLocale("es").toFormat("LL"),
            nowYear = DateTime.now().setZone("America/Mexico_City").setLocale("es").toFormat("y"),
            nowDate = Date.parse(nowYear + '-' + nowMonth + '-' + nowDay),
            deadlineDate = Date.parse(dayDeadline),
            nowTimeHours = DateTime.now().setZone("America/Mexico_City").setLocale("es").toFormat("T"),
            dateTest = new Date(nuevaCarrera.carrera),
            diaSemana = dateTest.getDay(),
            dias = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
            nombreDia = dias[diaSemana];

        console.log(nuevaCarrera.carrera)
        console.log(nombreDia)
        console.log('ahora: ' + nowDate)
        console.log('deadline: ' + deadlineDate)
        if(nowDate > deadlineDate) {
            console.log('pasó deadline')
        } else {
            console.log('aún pasa')
        }*/

        /*console.log('hora actual: ' + nowTimeHours)
        console.log('deadline: ' + hourDeadline)
        if(nowTimeHours > hourDeadline) {
            console.log('pasó deadline')
        } else {
            console.log('aún pasa')
        }*/
        mostrarAlerta('Datos guardados', 'alerta-ok');
        actualizarCarrera(nuevaCarrera);
    }

    return (
        <div className='outer-wrapper'>
            {alerta ? (<div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>) : null}
            <MainNavigation/>
            <section className='block activate-race'>
                <h1>{index} - {currentRace.pais}</h1>
                <h2>{currentRace.ciudad}</h2>
                <form onSubmit={handleSubmit}>
                    <div className='input-container'>
                        <label htmlFor='type'>Selecciona el tipo de Carrera:</label>
                        <select id='type' onChange={(ev) => { setTypeRace(ev.target.value) }} required>
                            <option value=''></option>
                            <option value='Normal'>Normal</option>
                            <option value='Sprint'>Sprint</option>
                        </select>
                    </div>
                    <div className='input-container'>
                        <label htmlFor='race_date'>Selecciona la fecha de inicio de la Carrera</label>
                        <input
                            id='race_date'
                            type='date'
                            onChange={(ev) => { setDateRaceBegin(ev.target.value) }}
                            required
                        />
                    </div>
                    <div className='input-container'>
                        <label htmlFor='race_date'>Selecciona la fecha de fin de la Carrera</label>
                        <input
                            id='race_date'
                            type='date'
                            onChange={(ev) => { setDateRaceEnd(ev.target.value) }}
                            required
                        />
                    </div>
                    <div className='input-container'>
                        <label htmlFor='race_hour'>Ingresa la fecha y hora de la Carrera:</label>
                        <input
                            id='race_hour'
                            type='datetime-local'
                            onChange={(ev) => { setHourRace(ev.target.value) }}
                            required
                        />
                    </div>
                    <div className='input-container'>
                        <label htmlFor='practice_one'>Ingresa la fecha y hora de la Práctica 1:</label>
                        <input
                            id='practice_one'
                            type='datetime-local'
                            onChange={(ev) => { setPractice_one(ev.target.value) }}
                            required
                        />
                    </div>
                    {
                        (typeRace === 'Normal')
                            ?
                                <div className='input-container'>
                                    <label htmlFor='practice_two'>Ingresa la fecha y hora de la Práctica 2:</label>
                                    <input
                                        id='practice_two'
                                        type='datetime-local'
                                        onChange={(ev) => { setPractice_two(ev.target.value) }}
                                        required
                                    />
                                </div>
                            :
                                <div className='input-container'>
                                    <label htmlFor='practice_two'>Ingresa la fecha y hora de la Sprint Quali:</label>
                                    <input
                                        id='practice_two'
                                        type='datetime-local'
                                        onChange={(ev) => { setSprintQuali(ev.target.value) }}
                                        required
                                    />
                                </div>
                    }
                    {
                        (typeRace === 'Normal')
                            ?
                                <div className='input-container'>
                                    <label htmlFor='practice_three'>Ingresa la fecha y hora de la Práctica 3:</label>
                                    <input
                                        id='practice_three'
                                        type='datetime-local'
                                        onChange={(ev) => {
                                            setPractice_three(ev.target.value)
                                            setSprint('')
                                        }}
                                        required
                                    />
                                </div>
                            :
                                <div className='input-container'>
                                    <label htmlFor='sprint'>Ingresa la fecha y hora del Sprint:</label>
                                    <input
                                        id='sprint'
                                        type='datetime-local'
                                        onChange={(ev) => {
                                            setPractice_three('')
                                            setSprint(ev.target.value)
                                        }}
                                        required
                                    />
                                </div>
                    }
                    <div className='input-container'>
                        <label htmlFor='qualifying'>Ingresa la fecha y hora del Qualifying:</label>
                        <input
                            id='qualifying'
                            type='datetime-local'
                            onChange={(ev) => { setQualifying(ev.target.value) }}
                            required
                        />
                    </div>
                    <div className='input-container'>
                        <label htmlFor='deadline_day'>Selecciona el día de Deadline:</label>
                        <input
                            id='deadline_day'
                            type='date'
                            onChange={(ev) => { setDayDeadline(ev.target.value) }}
                            required
                        />
                    </div>
                    <div className='input-container'>
                        <label htmlFor='deadline_day'>Selecciona la Hora de Deadline:</label>
                        <input
                            id='deadline_day'
                            onChange={(ev) => { setHourDeadline(ev.target.value) }}
                            required
                            type='time'
                        />
                    </div>
                    <div className='input-container'>
                        <label htmlFor='active'>¿Deseas activar la Carrera?</label>
                        <select
                            id='active'
                            onChange={(ev) => { setActive(ev.target.value) }}
                            required
                        >
                            <option value=''></option>
                            <option value='true'>Sí</option>
                            <option value='false'>No</option>
                        </select>
                    </div>
                    <input
                        type='submit'
                        value='Guardar'
                    />
                </form>
                <Link to={"/administrador"} className="back">
                    <div className="text">Regresar</div>
                </Link>
            </section>
        </div>
    );
}

export default ActivarCarrera;