import React, { useReducer } from 'react';

import listaCarrerasContext from './listaCarrerasContext';
import listaCarrerasReducer from './listaCarrerasReducer';
import {
            OBTENER_CARRERAS,
            ACTUALIZAR_CARRERA
        } from '../../types';

import clienteAxios from "../../config/axios";

const ListaCarrerasState = props => {

    const initialState = {    
        carreras: []
    }

    // Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(listaCarrerasReducer, initialState);

    // Obtener los concursos
    const obtenerCarreras = async () => {
        try {
            const resultado = await clienteAxios.get('/api/listacarreras');
            dispatch({
                type:OBTENER_CARRERAS,
                payload: resultado.data.carreras
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                //type: PROYECTO_ERROR,
                payload: alerta
            })
        }
    }

    // Edita o mofifica una tarea
    const actualizarCarrera = async carrera => {
        try {
            const resultado = await clienteAxios.put(`/api/listacarreras/${carrera._id}`, carrera);
            dispatch({
                type: ACTUALIZAR_CARRERA,
                payload: resultado.data.carrera
            });
        } catch (error) {
            console.log(error);
        }
    }

    return(
        <listaCarrerasContext.Provider
            value={{
                carreras: state.carreras,
                obtenerCarreras,
                actualizarCarrera
            }}
        >
            {props.children}
        </listaCarrerasContext.Provider>
    )
}

export default ListaCarrerasState;