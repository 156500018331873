import React, { useReducer } from 'react';

import listaPilotosContext from './listaPilotosContext';
import listaPilotosReducer from './listaPilotosReducer';
import {
            OBTENER_PILOTOS
        } from '../../types';

import clienteAxios from "../../config/axios";

const ListaPilotosState = props => {

    const initialState = {    
        pilotos: []
    }

    // Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(listaPilotosReducer, initialState);

    // Obtener los concursos
    const obtenerPilotos = async () => {
        try {
            const resultado = await clienteAxios.get('/api/listapilotos');
            dispatch({
                type:OBTENER_PILOTOS,
                payload: resultado.data.pilotos
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                //type: PROYECTO_ERROR,
                payload: alerta
            })
        }
    }

    return(
        <listaPilotosContext.Provider
            value={{
                pilotos: state.pilotos,
                obtenerPilotos,
            }}
        >
            {props.children}
        </listaPilotosContext.Provider>
    )
}

export default ListaPilotosState;