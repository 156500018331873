import React, {useContext, useEffect} from 'react';
import ListaCarreras from './ListaCarreras';
import MainNavigation from '../partials/MainNavigation';
import listaParticipantesContext from '../../context/listaParticipantes/listaParticipantesContext';
import listaCarrerasContext from "../../context/listaCarreras/listaCarrerasContext";
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Import styles
import '../../design/styles/races-wrapper.scss';
import '../../design/styles/sideBar-races.scss';
import '../../design/styles/cover-races.scss';
import '../../design/styles/races-list.scss';
import sideBar from '../../design/imgs/barra-lateral.jpg';
import firstPlace from '../../design/imgs/gold.svg';
import secondPlace from '../../design/imgs/silver.svg';
import thirdPlace from '../../design/imgs/bronce.svg';
import fourthPlace from '../../design/imgs/medalla.svg';
import coverConcurso01 from '../../design/imgs/01-cover-warm-up-pass.jpg';
import coverConcurso02 from '../../design/imgs/02-cover-hospitality-pass.jpg';
import coverConcurso03 from '../../design/imgs/03-cover-pit-lane-pass.jpg';
import coverConcurso04 from '../../design/imgs/04-cover-paddock-pass.jpg';

import backIcon from '../../design/imgs/arrow-l-1.svg';

const Carreras = () => {

    let concurso = JSON.parse(localStorage.getItem('currentConcurso'));

    // Obtener las tareas del proyecto
    const listaContext = useContext(listaParticipantesContext);
    const {obtenerParticipantes} = listaContext;

    // Obtener las carreras
    const carrerasContext = useContext(listaCarrerasContext);
    const {carreras, obtenerCarreras} = carrerasContext;

    const SideBarBackground = styled.div`
        background-image: url(${sideBar});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    `;

    const CoverBackground = styled.div`
        background-image: url(${concurso.cover_src});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        &:after {
            background-image: linear-gradient(180deg, rgba(0,0,0,0.20) 0%, #000000 100%);
            content: '';
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    `;

    const cargaListaUsuarios = id => {
        obtenerParticipantes(id);
    }

    useEffect(() => {
        obtenerCarreras();
    }, [])

    return (
        <div className='outer-wrapper'>
            <img className='hidden' src={coverConcurso01} alt='Imagen Concurso'/>
            <img className='hidden' src={coverConcurso02} alt='Imagen Concurso'/>
            <img className='hidden' src={coverConcurso03} alt='Imagen Concurso'/>
            <img className='hidden' src={coverConcurso04} alt='Imagen Concurso'/>
            <MainNavigation/>
            <div className='races-wrapper'>
                <section className='block sideBar-races'>
                    <SideBarBackground/>
                    <div className='holder'>
                        <div className='content'>
                            <Link className='button' to={'/tabla-general'} onClick={() => cargaListaUsuarios(concurso._id)}>Tabla General</Link>
                            {/*<a
                                className='button'
                                href={'/files/tabla-' + concurso._id + '.pdf'}
                                target="_blank" rel="noopener noreferrer"
                            >Tabla General</a>*/}

                            {/*<Link
                                to={"resultados-carrera"}
                                className='button'
                                onClick={
                                    () => {
                                        cargaListaUsuarios(concurso._id);
                                        localStorage.setItem("index", "13");
                                    }
                                }
                            >Estrategias</Link>*/}
                            <Link to={'/dashboard'} className='back hidden-xs'>
                                <div className='icon'>
                                    <img
                                        src={backIcon}
                                        alt="Regresar"
                                    />
                                </div>
                                <div className='text'>Regresar</div>
                            </Link>
                        </div>
                    </div>
                </section>
                <section className='block cover-races'>
                    <CoverBackground/>
                    <div className='holder'>
                        <div className='container-fluid'>
                            <div className='content'>
                                <div className='flex-content'>
                                    <div className='left-side'>
                                        <div className='title'>F1 QUINIELA 2024</div>
                                        <div className='desc'>{concurso.nombre}</div>
                                        <div className='credits'>{concurso.creditos}CR</div>
                                        <div className='bolsa'>Bolsa en juego: {concurso.bolsa}<span>CR</span></div>
                                    </div>
                                    <div className='right-side hidden-xs'>
                                        <div className="prizes-title">Premios para:</div>
                                        <ul>
                                            <li>
                                                <span className="position">1º lugar</span>
                                                <span className="percentage">{concurso.premio1}%</span>
                                                <span className="icon">
                                                    <img src={firstPlace} alt="Primer lugar" />
                                                </span>
                                            </li>
                                            <li>
                                                <span className="position">2º lugar</span>
                                                <span className="percentage">{concurso.premio2}%</span>
                                                <span className="icon">
                                                    <img src={secondPlace} alt="Segundo lugar" />
                                                </span>
                                            </li>
                                            <li>
                                                <span className="position">3º lugar</span>
                                                <span className="percentage">{concurso.premio3}%</span>
                                                <span className="icon">
                                                    <img src={thirdPlace} alt="Tercer lugar" />
                                                </span>
                                            </li>
                                            <li>
                                                <span className="position">10º lugar</span>
                                                <span className="percentage">{concurso.premio4}%</span>
                                                <span className="icon">
                                                    <img src={fourthPlace} alt="Extra" />
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ListaCarreras
                    carreras={carreras}
                    concurso={concurso}
                />
            </div>
        </div>
    );
}

export default Carreras;