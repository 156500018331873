import React, {useContext, useEffect} from 'react'
import MainNavigation from '../partials/MainNavigation';
import listaParticipantesContext from '../../context/listaParticipantes/listaParticipantesContext';
import AuthContext from '../../context/autenticacion/authContext';
import { Link } from 'react-router-dom';

// Import styles
import '../../design/styles/overall-table.scss';

import backIcon from '../../design/imgs/arrow-l-1.svg';

const OverallTable = () => {

    let concurso = JSON.parse(localStorage.getItem('currentConcurso'));

    // Obtener las tareas del proyecto
    const listaContext = useContext(listaParticipantesContext);
    const {participantesInscritos, obtenerParticipantes} = listaContext;

    // Extraer la información de autenticación
    const authContext = useContext(AuthContext);
    const {usuario} = authContext;

    let primerValor = 0;

    useEffect(() => {
        obtenerParticipantes(concurso._id);
    }, [])

    return (
        <div className='outer-wrapper'>
            <MainNavigation/>
            <section className='block overall-table'>
                <div className='holder'>
                    <div className='container-fluid'>
                        <div className='header'>
                            <div className='title'>F1 QUINIELA 2024</div>
                            <div className='desc'>{concurso.nombre}</div>
                            <div>
                                Última Actualización: GP

                                {participantesInscritos.map((participant, index) => 
                                    (participant.id_participante === usuario._id)
                                        ?
                                            (participant.carreras_terminadas === 1)
                                                ?
                                                    ' Bahrain - Sakhir'
                                                :
                                                    (participant.carreras_terminadas === 2)
                                                        ?
                                                            ' Arabia Saudita - Jeddah'
                                                        :
                                                            (participant.carreras_terminadas === 3)
                                                                ?
                                                                    ' Australia - Melbourne'
                                                                :
                                                                    (participant.carreras_terminadas === 4)
                                                                        ?
                                                                            ' Japón - Suzuka'
                                                                        :
                                                                            (participant.carreras_terminadas === 5)
                                                                                ?
                                                                                    ' China - Shanghai'
                                                                                :
                                                                                    (participant.carreras_terminadas === 6)
                                                                                        ?
                                                                                            ' Estados Unidos - Miami'
                                                                                        :
                                                                                            (participant.carreras_terminadas === 7)
                                                                                                ?
                                                                                                    ' Italia - Imola'
                                                                                                :
                                                                                                    (participant.carreras_terminadas === 8)
                                                                                                        ?
                                                                                                            ' Mónaco - Monte Carlo'
                                                                                                        :
                                                                                                            (participant.carreras_terminadas === 9)
                                                                                                                ?
                                                                                                                    ' Canadá - Montreal'
                                                                                                                :
                                                                                                                    (participant.carreras_terminadas === 10)
                                                                                                                        ?
                                                                                                                            ' España - Barcelona'
                                                                                                                        :
                                                                                                                            (participant.carreras_terminadas === 11)
                                                                                                                                ?
                                                                                                                                    ' Austria - Spielberg'
                                                                                                                                :
                                                                                                                                    (participant.carreras_terminadas === 12)
                                                                                                                                        ?
                                                                                                                                            ' Gran Bretaña - Silverstone'
                                                                                                                                        :
                                                                                                                                            (participant.carreras_terminadas === 13)
                                                                                                                                                ?
                                                                                                                                                    ' Hungría - Budapest'
                                                                                                                                                :
                                                                                                                                                    (participant.carreras_terminadas === 14)
                                                                                                                                                        ?
                                                                                                                                                            ' Bélgica - Spa-Francorchamps'
                                                                                                                                                        :
                                                                                                                                                            (participant.carreras_terminadas === 15)
                                                                                                                                                                ?
                                                                                                                                                                    ' Holanda - Zandvoort'
                                                                                                                                                                :
                                                                                                                                                                    (participant.carreras_terminadas === 16)
                                                                                                                                                                        ?
                                                                                                                                                                            ' Italia - Monza'
                                                                                                                                                                        :
                                                                                                                                                                            (participant.carreras_terminadas === 17)
                                                                                                                                                                                ?
                                                                                                                                                                                    ' Azerbaijan - Baku'
                                                                                                                                                                                :
                                                                                                                                                                                    (participant.carreras_terminadas === 18)
                                                                                                                                                                                        ?
                                                                                                                                                                                            ' Singapur - Singapur'
                                                                                                                                                                                        :
                                                                                                                                                                                            (participant.carreras_terminadas === 19)
                                                                                                                                                                                                ?
                                                                                                                                                                                                    ' USA* - Austin'
                                                                                                                                                                                                :
                                                                                                                                                                                                    (participant.carreras_terminadas === 20)
                                                                                                                                                                                                        ?
                                                                                                                                                                                                            ' México - CDMX'
                                                                                                                                                                                                        :
                                                                                                                                                                                                            (participant.carreras_terminadas === 21)
                                                                                                                                                                                                                ?
                                                                                                                                                                                                                    ' Brasil - Sao Paulo'
                                                                                                                                                                                                                :
                                                                                                                                                                                                                    (participant.carreras_terminadas === 22)
                                                                                                                                                                                                                        ?
                                                                                                                                                                                                                            ' Estados Unidos - Las Vegas'
                                                                                                                                                                                                                        :
                                                                                                                                                                                                                            (participant.carreras_terminadas === 23)
                                                                                                                                                                                                                                ?
                                                                                                                                                                                                                                    ' Qatar - Losail'
                                                                                                                                                                                                                                :
                                                                                                                                                                                                                                    (participant.carreras_terminadas === 24)
                                                                                                                                                                                                                                        ?
                                                                                                                                                                                                                                            ' Emiratos Árabes - Abu Dhabi'
                                                                                                                                                                                                                                        :
                                                                                                                                                                                                                                            null
                                    :
                                        null
                                )}
                            </div>
                        </div>
                        <div className='content'>
                            <div className='gplk-table'>
                                <div className='gplk-t-head'>Posiciones</div>
                                <div className='gplk-t-body'>
                                    <div className='gplk-t-row main-row'>
                                        <div className='col-1 col'>
                                            <span className='hidden-xs'>Posición</span>
                                            <span className='visible-xs'>Pos</span>
                                        </div>
                                        <div className='col-2 col'>Participante</div>
                                        <div className='col-3 col'>
                                            <span className='hidden-xs'>Puntos</span>
                                            <span className='visible-xs'>Pts</span>
                                        </div>
                                        <div className='col-4 col'>
                                            <span className='hidden-xs'>Diferencia 1er lugar</span>
                                            <span className='visible-xs'>Dif</span>
                                        </div>
                                    </div>
                                    {participantesInscritos.map((participant, index) => 
                                        <div className='gplk-t-row general-row' key={index} id={(participant.id_participante === usuario._id) ? 'active': null}>
                                            <div className='col-1 col'>{index + 1}</div>
                                            <div className='col-2 col'>{participant.nickname}</div>
                                            <div className='col-3 col'>{participant.puntos}</div>
                                            {(index === 0)
                                                ?
                                                <div className='col-4 col'>
                                                    0
                                                    <span className='hidden'>{primerValor = participant.puntos}</span>
                                                </div>
                                                :
                                                <div className='col-4 col'>
                                                    {(primerValor === 0) ? primerValor = participant.puntos : primerValor - participant.puntos}
                                                </div>
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Link to={'/carreras'} className='back'>
                    <div className='icon'>
                        <img
                            src={backIcon}
                            alt="Regresar"
                        />
                    </div>
                    <div className='text'>Regresar</div>
                </Link>
            </section>
        </div>
    );
}

export default OverallTable;