import React, { useReducer } from 'react';

import listaParticipantesContext from './listaParticipantesContext';
import listaParticipantesReducer from './listaParticipantesReducer';
import {
        OBTENER_PARTICIPANTES,
        OBTENER_PARTICIPANTES_HOSPITALITY,
        OBTENER_PARTICIPANTES_WARM,
        OBTENER_PARTICIPANTES_BOX,
        OBTENER_PARTICIPANTES_PADDOCK,
        OBTENER_PARTICIPANTES_WOMEN,
        OBTENER_PARTICIPANTES_POR_USUARIO,
        AGREGAR_PARTICIPANTE,
        ACTUALIZAR_PARTICIPANTE
    } from '../../types';

import clienteAxios from "../../config/axios";

const ListaParticipantesState = props => {

    const initialState = {
        participantesInscritos: [],
        participantesInscritosHospitality: [],
        participantesInscritosWarm: [],
        participantesInscritosBox: [],
        participantesInscritosPadock: [],
        participantesInscritosPorUsuario: []
    }

    // Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(listaParticipantesReducer, initialState);

    // Obtener los participantes
    const obtenerParticipantes = async id_concurso => {
        try {
            const resultado = await clienteAxios.get('/api/listaParticipantes', {params: {id_concurso}});
            dispatch({
                type:OBTENER_PARTICIPANTES,
                payload: resultado.data.participantes
            })
        } catch (error) {
            console.log(error);
        }
    }

    // Obtener los participantes
    const obtenerParticipantesHospitality = async id_concurso => {
        try {
            const resultado = await clienteAxios.get('/api/listaParticipantes', {params: {id_concurso}});
            dispatch({
                type:OBTENER_PARTICIPANTES_HOSPITALITY,
                payload: resultado.data.participantes
            })
        } catch (error) {
            console.log(error);
        }
    }

    // Obtener los participantes
    const obtenerParticipantesWarm = async id_concurso => {
        try {
            const resultado = await clienteAxios.get('/api/listaParticipantes', {params: {id_concurso}});
            dispatch({
                type:OBTENER_PARTICIPANTES_WARM,
                payload: resultado.data.participantes
            })
        } catch (error) {
            console.log(error);
        }
    }

    // Obtener los participantes
    const obtenerParticipantesBox = async id_concurso => {
        try {
            const resultado = await clienteAxios.get('/api/listaParticipantes', {params: {id_concurso}});
            dispatch({
                type:OBTENER_PARTICIPANTES_BOX,
                payload: resultado.data.participantes
            })
        } catch (error) {
            console.log(error);
        }
    }

    // Obtener los participantes
    const obtenerParticipantesPaddock = async id_concurso => {
        try {
            const resultado = await clienteAxios.get('/api/listaParticipantes', {params: {id_concurso}});
            dispatch({
                type:OBTENER_PARTICIPANTES_PADDOCK,
                payload: resultado.data.participantes
            })
        } catch (error) {
            console.log(error);
        }
    }

    // Obtener los participantes
    const obtenerParticipantesWomen = async id_concurso => {
        try {
            const resultado = await clienteAxios.get('/api/listaParticipantes', {params: {id_concurso}});
            dispatch({
                type:OBTENER_PARTICIPANTES_WOMEN,
                payload: resultado.data.participantes
            })
        } catch (error) {
            console.log(error);
        }
    }

    // Obtener los participantes
    const obtenerParticipantesPorUsuario = async id_participante => {
        try {
            const resultado = await clienteAxios.get('/api/listaParticipantesPorUsuario', {params: {id_participante}});
            dispatch({
                type:OBTENER_PARTICIPANTES_POR_USUARIO,
                payload: resultado.data.participantesPorUsuario
            })
        } catch (error) {
            console.log(error);
        }
    }

    //Agregar un participante al concurso seleccionado
    const agregarParticipante = async participante => {
        try {
            const resultado = await clienteAxios.post('/api/listaParticipantes', participante);
            dispatch({
                type: AGREGAR_PARTICIPANTE,
                payload: resultado.data
            })
        } catch (error) {
            console.log(error);
        }
    }

    // Edita o mofifica una tarea
    const actualizarParticipante = async participante => {
        try {
            const resultado = await clienteAxios.put(`/api/listaParticipantes/${participante._id}`, participante);
            dispatch({
                type: ACTUALIZAR_PARTICIPANTE,
                payload: resultado.data.participante
            });
        } catch (error) {
            console.log(error);
        }
    }

    return(
        <listaParticipantesContext.Provider
            value={{
                participantesInscritos: state.participantesInscritos,
                participantesInscritosHospitality: state.participantesInscritosHospitality,
                participantesInscritosWarm: state.participantesInscritosWarm,
                participantesInscritosBox: state.participantesInscritosBox,
                participantesInscritosPaddock: state.participantesInscritosPaddock,
                participantesInscritosWomen: state.participantesInscritosWomen,
                participantesInscritosPorUsuario: state.participantesInscritosPorUsuario,
                obtenerParticipantes,
                obtenerParticipantesHospitality,
                obtenerParticipantesWarm,
                obtenerParticipantesBox,
                obtenerParticipantesPaddock,
                obtenerParticipantesWomen,
                obtenerParticipantesPorUsuario,
                agregarParticipante,
                actualizarParticipante
            }}
        >
            {props.children}
        </listaParticipantesContext.Provider>
    )
}

export default ListaParticipantesState;