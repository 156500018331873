import React, {useContext, useEffect} from 'react';
import AuthContext from '../../context/autenticacion/authContext';
import brwIcon from '../../design/imgs/brw.svg';
import userIcon from '../../design/imgs/user.svg';
import creditsIcon from '../../design/imgs/cr.svg';
import rulesIcon from '../../design/imgs/reglas.svg';
import exitIcon from '../../design/imgs/salir.svg';

// Import styles
import '../../design/styles/navigation.scss';

import { Link } from 'react-router-dom';

const MainNavigation = () => {

    // Extraer la información de autenticación
    const authContext = useContext(AuthContext);
    const {usuario, usuarioAutenticado, cerrarSesion} = authContext;

    useEffect(() => {
        usuarioAutenticado();
        // eslint-disable-next-line
    }, []);

    const expandMenu = () => {
        let listWrapper = document.querySelector('.list-wrapper'),
            body = document.querySelector('body');

        if (window.innerWidth < 767) {
            if (listWrapper.classList.contains('list-expanded')) {
                listWrapper.classList.remove('list-expanded');
                body.classList.remove('no-scroll');
            } else {
                listWrapper.classList.add('list-expanded');
                body.classList.add('no-scroll');
            }
        }
    }

    return (
        <nav className='navigation'>
            <div className='holder'>
                <div className='content'>
                    <Link to={'/dashboard'} className='logo'>
                        <img
                            src={brwIcon}
                            alt="BRW"
                        />
                    </Link>
                    <div className='list-wrapper'>
                        <ul className='list'>
                            {
                                (usuario) ?
                                (usuario.email === 'david7_qh@hotmail.com' || usuario.email === 'jdecolombres_11@hotmail.com' || usuario.email === 'alvarordlp@gmail.com')
                                    ?
                                        <Link
                                            to={'/administrador'}
                                            className="option toggle-btn-to-expand"
                                            onClick={() => expandMenu()}
                                        >
                                            <span className='icon'>
                                                <img
                                                    src={userIcon}
                                                    alt="usuario"
                                                />
                                            </span>
                                            <span className='text'>Admin</span>
                                        </Link>
                                    :
                                        null
                                : null
                            }
                            <Link
                                to={'/perfil'}
                                className="option toggle-btn-to-expand"
                                onClick={() => expandMenu()}
                            >
                                <span className='icon'>
                                    <img
                                        src={userIcon}
                                        alt="usuario"
                                    />
                                </span>
                                <span className='text'>Mi Perfil</span>
                            </Link>
                            <Link
                                to={'/creditos'}
                                className="option toggle-btn-to-expand"
                                onClick={() => expandMenu()}
                            >
                                <span className='icon'>
                                    <img
                                        src={creditsIcon}
                                        alt="créditos"
                                    />
                                </span>
                                <span className='text'>Créditos</span>
                            </Link>
                            <a className="option toggle-btn-to-expand" href="/files/reglamento.pdf" target="_blank" rel="noopener noreferrer" onClick={() => expandMenu()}>
                                <span className='icon'>
                                    <img
                                        src={rulesIcon}
                                        alt="reglas"
                                    />
                                </span>
                                <span className='text'>Reglas</span>
                            </a>
                            <button
                                className='option toggle-btn-to-expand'
                                onClick={() => cerrarSesion()}
                            >
                                <span className='icon'>
                                    <img
                                        src={exitIcon}
                                        alt="salir"
                                    />
                                </span>
                                <span className='text'>Salir</span>
                            </button>
                        </ul>
                    </div>

                    <button className="toggle-btn visible-xs toggle-btn-to-expand" aria-label="Center Align" onClick={() => expandMenu()}>
                        <span className="bar"></span>
                        <span className="bar"></span>
                        <span className="bar"></span>
                    </button>
                </div>
            </div>
        </nav>
    );
}

export default MainNavigation;