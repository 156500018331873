import {
    OBTENER_CARRERAS,
    ACTUALIZAR_CARRERA
} from '../../types';

const reducer = (state, action) => {
switch(action.type) {
    case OBTENER_CARRERAS:
        return {
            ...state,
            carreras: action.payload
        }

    case ACTUALIZAR_CARRERA:
        return {
            ...state,
            carreras: state.carreras.map(carrera => carrera._id === action.payload._id ? action.payload : carrera)
        }
    default:
        return state;
    }
}

export default reducer;