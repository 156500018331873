import React, {useContext, useEffect} from 'react';
import AuthContext from '../../context/autenticacion/authContext';
import listaParticipantesContext from '../../context/listaParticipantes/listaParticipantesContext';
import concursoContext from '../../context/concursos/concursoContext';
import { Link } from 'react-router-dom';

import backIcon from '../../design/imgs/arrow-l-1.svg';

// Import styles
import '../../design/styles/profile.scss';

// Import partial
import MainNavigation from '../partials/MainNavigation';
import FooterHome from '../partials/FooterRegister';

const Profile = () => {

    // Extraer la información de autenticación
    const authContext = useContext(AuthContext);
    const {usuario, usuarioAutenticado} = authContext;

    // Obtener las tareas del proyecto
    const listaContext = useContext(listaParticipantesContext);
    const {participantesInscritosPorUsuario, obtenerParticipantesPorUsuario} = listaContext;

    // Extraer concursos de state inicial
    const concursosContext = useContext(concursoContext);
    const {concursosPorId, obtenerConcursosPorId} = concursosContext;

    let array = [],
        creditosEnJuego = 0;

    useEffect(() => {
        usuarioAutenticado();

        if (usuario) {
            obtenerParticipantesPorUsuario(usuario._id);
        }
        // eslint-disable-next-line
    }, []);

    const onclick = () => {

        if (usuario) {
            
            participantesInscritosPorUsuario.map(participante => {
                obtenerConcursosPorId(participante.id_concurso);
            });
        }
    }

    return (

        <div className='outer-wrapper'>
            <MainNavigation/>
            <section className='block profile'>
                <div className='background'></div>
                <div className='holder'>
                    <div className='container-fluid'>
                        <div className='header'>
                            <div className='title'>Mi perfil</div>
                        </div>
                        <div className='content'>
                            <div className='flex-content'>
                                <div className='text-wrapper'>
                                    <div className='flex-content'>
                                        <div className='info-wrapper'>
                                            <div className='info'>
                                                { usuario ? <div className='nombre-usuario'><span>{usuario.nombre}</span></div> : null }
                                            </div>
                                            <div className='info-label'>Nombre</div>
                                        </div>
                                        <div className='info-wrapper'>
                                            <div className='info'>
                                                { usuario ? <div className='nombre-usuario'><span>{usuario.apellidos}</span></div> : null }
                                            </div>
                                            <div className='info-label'>Apellidos</div>
                                        </div>
                                    </div>
                                    <div className='flex-content'>
                                        <div className='info-wrapper'>
                                            <div className='info'>
                                                { usuario ? <div className='nombre-usuario'><span>{usuario.email}</span></div> : null }
                                            </div>
                                            <div className='info-label'>Correo</div>
                                        </div>
                                        <div className='info-wrapper'>
                                            <div className='info'>
                                                { usuario ? <div className='nombre-usuario'><span>{usuario.telefono}</span></div> : null }
                                            </div>
                                            <div className='info-label'>Teléfono</div>
                                        </div>
                                    </div>
                                    <div className='flex-content'>
                                        {/*<div className='info-wrapper'>
                                            <div onClick={onclick} className="see-credits" id='creditsBtn'>Ver mis créditos</div>
                                            <div className='info'>
                                                {concursosPorId.map(concurso => {
                                                    array.push(parseInt(concurso[0].creditos));
                                                })}
                                                {array.map(value => {
                                                    creditosEnJuego += value;
                                                })}
                                                <div className='creditos'>{creditosEnJuego}<span>CR</span></div>
                                            </div>
                                            <div className='info-label'>Créditos en juego</div>
                                            </div>*/}
                                        <div className='info-wrapper'>
                                            <div className='info'>
                                                { usuario ? <div className='creditos'>{usuario.creditos}<span>CR</span></div> : null }
                                            </div>
                                            <div className='info-label'>Créditos disponibles</div>
                                        </div>
                                    </div>
                                </div>
                                {/*<div className='competitions'>
                                    <div className='table-gplk'>
                                        <div className='t-header-gplk'>
                                            <div className='t-title'>Concursos</div>
                                        </div>
                                        <div className='t-body-gplk'>
                                            {concursosPorId.map(concurso => (
                                                <div key={concurso[0]._id} className="concurso">
                                                    <div className='concurso-name'>
                                                        {concurso[0].nombre}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <Link to={'/dashboard'} className='back'>
                    <div className='icon'>
                        <img
                            src={backIcon}
                            alt="Regresar"
                        />
                    </div>
                    <div className='text'>Regresar</div>
                </Link>
            </section>
            <FooterHome/>
        </div>
    );
}
export default Profile;